import { Query, Mutation, MutationFn } from 'react-apollo';
import { DataProxy } from 'apollo-cache';
import gql from 'graphql-tag';
import _ from 'lodash';

import { url } from 'inkp-routes';
import {
  Cart,
  CartItem,
  Query as QueryResults,
  QueryrequestCartQuoteArgs,
  Mutation as MutationResults,
  MutationupdateCartArgs,
  RequestCompleteQuoteOutput,
  QuoteCouponResponse,
  AmountsInput,
  Amounts,
  QUOTE_COUPON_RESPONSE_LEVEL_ENUM,
} from 'inkp-order-sdk/types.g';
import { COUPON_SOURCE_ENUM } from 'inkp-coupon-sdk/types.g';
import { parseProductItemId, stringifyProductItemId } from 'inkp-product-sdk/productItem';
import { Product, ProductColor } from 'inkp-product-sdk/types.g';
import { Design, DesignSide, DESIGN_SIDE_ENUM } from 'inkp-design-sdk/types.g';
import { DESIGN_SIDE_PRINT_SIDE_MAP } from 'inkp-design-sdk/design';
import { findProductColor } from 'inkp-product-sdk/product';
import {
  isMinimumQuantityRequired,
  getCartProductTotalQuantity,
  MAX_PRODUCT_QUANTITY,
  MINIMUM_DTG_QUANTITY,
} from 'inkp-order-sdk/order';
import { ZERO_AMOUNTS } from 'inkp-order-sdk/billing';
import mockupRoutes from 'inkp-mockup-sdk/routes';

import { CURRENT_USER_FRAGMENT } from '../util/login';
import { CartProduct, SizesQuantities, TemporalCartItem } from '../interfaces/CartProduct';
import { CartProductWithQuotes } from '../interfaces/CartCheckout';
import { DesignCartDesignSide } from '../states/global/designCart';
import { DESIGN_FRAGMENT } from '../graphql/designs';

const MOCKUP_SIZE: string = 'medium';

export const STRAIGHTEN_SVG = 'https://inkp-production.32pt.com/public/assets/mdi-straighten-icon.svg';

export enum CART_PRODUCT_WARNINGS {
  MINIMUM_QUANTITY_REQUIRED = 'MINIMUM_QUANTITY_REQUIRED',
}

export enum CART_PRODUCT_ERRORS {
  QUANTITY_REQUIRED = 'QUANTITY_REQUIRED',
  MINIMUM_QUANTITY_REQUIRED = 'MINIMUM_QUANTITY_REQUIRED',
  MAXIMUM_QUANTITY_EXCEEDED = 'MAXIMUM_QUANTITY_EXCEEDED',
  ITEM_OUT_OF_STOCK = 'ITEM_OUT_OF_STOCK',
}

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    name
    brand
    styleId
    tags {
      brand
    }
    categories {
      name
      scope
    }
    colors {
      name
      sizes
      inStock
      hex
      images {
        label
        url
      }
      printTypes {
        SCREENPRINT
        DTG
      }
      sizesObjects {
        id
        name
        inStock
      }
    }
    inStock
    printTypes {
      SCREENPRINT
      DTG
    }
    designTemplates {
      id
      side
    }
    detailTemplates {
      id
      side
    }
    sides {
      name
      printZones
    }
  }
`;

export const CART_ITEM_FRAGMENT = gql`
  fragment CartItemFragment on CartItem {
    product {
      quantity
      productItemId
      mockupUrl
      mockups {
        side
        mockupUrl
      }
      productItem {
        productId
        color
        speed
      }
      product {
        sides {
          name
          printZonesObjects {
            id
          }
        }
      }
    }
    designId
    design {
      ...DesignFragment
    }
  }
  ${DESIGN_FRAGMENT}
`;

export const CHECKOUT_CART_FRAGMENT = gql`
  fragment CheckoutCartFragment on Cart {
    id
    items {
      ...CartItemFragment
    }
    shippingDetails {
      speed
    }
    shippingAddress {
      name
      company
      address1
      address2
      city
      state
      zip
      country
      phone
    }
    billingAddress {
      name
      company
      address1
      address2
      city
      state
      zip
      country
      phone
    }
    coupons {
      code
    }
    features {
      requiresCustomerApproval
      customerInstructions
    }
    chargeToken
  }

  ${CART_ITEM_FRAGMENT}
`;

export const PRODUCTS_QUERY = gql`
  query ProductsById($ids: [String!]!) {
    productsById(ids: $ids) {
      ...ProductFragment
    }
  }

  ${PRODUCT_FRAGMENT}
`;

export const QUERY_CURRENT_USER = gql`
  {
    currentUser {
      ...CurrentUser
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

export const QUERY_CART_BY_USER = gql`
  query Cart {
    cartByUser {
      ...CheckoutCartFragment
    }
  }
  ${CHECKOUT_CART_FRAGMENT}
`;
export class QueryCartByUser extends Query<QueryResults> {}

export class QueryRequestCartQuote extends Query<QueryResults, QueryrequestCartQuoteArgs> {}
export const QUERY_REQUEST_CART_QUOTE = gql`
  query RequestCartQuote($cartId: String!) {
    requestCartQuote(cartId: $cartId) {
      printType
      designId
      couponResponse {
        code
        displayCode
        description
        source
        level
        message
        valueType
        value
        target
        discount
      }
      amounts {
        blanks
        printing
        shipping
        upcharge
        subtotal
        discount
        adjustment
        tax
        total
      }
      product {
        productItemId
        quantity
      }
    }
  }
`;

export const QUERY_REQUEST_SHIPPING_QUOTES = gql`
  query RequestShippingQuotes($amounts: AmountsInput!) {
    requestShippingQuotes(amounts: $amounts) {
      speed
      percent
      cost
    }
  }
`;

export const QUERY_REQUEST_COMPLETE_QUOTE_WITHOUT_PRINT_TYPE = gql`
  query RequestCompleteQuoteWithoutPrintType($items: [CompleteQuoteItemsWithoutPrintType!]!) {
    requestCompleteQuoteWithoutPrintType(items: $items) {
      designId
      amounts {
        blanks
        printing
        shipping
        upcharge
        subtotal
        discount
        adjustment
        tax
        total
      }
      product {
        productItemId
      }
    }
  }
`;

export class MutationUpdateCart extends Mutation<MutationResults, MutationupdateCartArgs> {}
export type MutationFnUpdateCart = MutationFn<MutationResults, MutationupdateCartArgs>;

export const MUTATION_UPDATE_CART = gql`
  mutation UpdateCart($id: ID!, $data: UpdatableCartFields!) {
    updateCart(id: $id, data: $data) {
      ...CheckoutCartFragment
    }
  }

  ${CHECKOUT_CART_FRAGMENT}
`;

export function updateCurrentCart(cache: DataProxy, data: Cart, query: any = QUERY_CART_BY_USER) {
  const updateData = _.merge({}, cache.readQuery({ query }), data);
  cache.writeQuery({
    query,
    data: { cartByUser: updateData },
  });
}

export function getProductsById(cache: DataProxy, productIds: string[]) {
  const { productsById }: any = cache.readQuery({
    query: PRODUCTS_QUERY,
    variables: { ids: productIds },
  });
  return productsById;
}

export function getCartByUser(cache: DataProxy, query: any = QUERY_CART_BY_USER) {
  const { cartByUser }: any = cache.readQuery({ query });
  return cartByUser;
}

export function getCartQuoteByCartId(cache: DataProxy, cartId: string) {
  const { requestCartQuote }: any = cache.readQuery({ query: QUERY_REQUEST_CART_QUOTE, variables: { cartId } });
  return requestCartQuote;
}

export function getCartAmounts(cartQuote: RequestCompleteQuoteOutput[]): Amounts {
  const emptyAmounts: Amounts = _.clone(ZERO_AMOUNTS);
  const totalAmounts: Amounts = cartQuote.reduce((amounts: Amounts, currentItemQuote: RequestCompleteQuoteOutput) => {
    return {
      blanks: amounts.blanks + currentItemQuote.amounts.blanks,
      printing: amounts.printing + currentItemQuote.amounts.printing,
      shipping: amounts.shipping + currentItemQuote.amounts.shipping,
      upcharge: amounts.upcharge + currentItemQuote.amounts.upcharge,
      subtotal: amounts.subtotal + currentItemQuote.amounts.subtotal,
      discount: amounts.discount + currentItemQuote.amounts.discount,
      adjustment: amounts.adjustment + currentItemQuote.amounts.adjustment,
      tax: amounts.tax + currentItemQuote.amounts.tax,
      total: amounts.total + currentItemQuote.amounts.total,
    };
  }, emptyAmounts);
  return totalAmounts;
}

export function findUserCoupon(cartQuote: RequestCompleteQuoteOutput[]): QuoteCouponResponse | undefined {
  const userCoupons: (QuoteCouponResponse | undefined)[] = cartQuote
    .map((currentItemQuote: RequestCompleteQuoteOutput) => {
      return currentItemQuote.couponResponse.find((couponResponse: QuoteCouponResponse) => {
        return (
          couponResponse.source !== COUPON_SOURCE_ENUM.INTERNAL &&
          couponResponse.level === QUOTE_COUPON_RESPONSE_LEVEL_ENUM.SUCCESS
        );
      });
    })
    .filter((userCoupon: QuoteCouponResponse | undefined) => userCoupon);
  return userCoupons[0];
}

export const formattedDate = (date: string) => {
  const [day, month, monthNum] = new Date(date).toString().split(' ');
  return `${day}, ${month} ${monthNum}`;
};

export const formatPrice = (price: number | undefined): string => {
  if (price === undefined) {
    return 'N/A';
  }
  return (price / 100).toFixed(2);
};

export const joinCartProductsWithQuotes = (
  cartProducts: CartProduct[],
  cartQuote: RequestCompleteQuoteOutput[]
): CartProductWithQuotes[] => {
  const cartProductsWithQuotes: CartProductWithQuotes[] = cartProducts.map((cartProduct) => {
    return {
      ...cartProduct,
      quotes: [],
    };
  });
  cartQuote.forEach((cartItemQuote: RequestCompleteQuoteOutput) => {
    const { designId } = cartItemQuote;
    const { productItemId } = cartItemQuote.product;
    const { productId, color: colorId } = parseProductItemId(productItemId);
    const cartProductId: string = JSON.stringify({
      productId,
      designId,
      colorId,
    });
    const cartProductWithQuote: CartProductWithQuotes | undefined = cartProductsWithQuotes.find(
      (cartProductWithQuote) => {
        return cartProductWithQuote.id === cartProductId;
      }
    );
    if (cartProductWithQuote) {
      cartProductWithQuote.quotes.push(cartItemQuote);
    }
  });
  return cartProductsWithQuotes;
};

export const getCartProductWarnings = (cartProduct: CartProduct): CART_PRODUCT_WARNINGS[] => {
  const productColor = findProductColor(cartProduct.product, cartProduct.colorId);
  let isDtgBanned = false;
  if (!productColor) {
    isDtgBanned = !cartProduct.product.printTypes.DTG;
  } else {
    isDtgBanned = !productColor.printTypes.DTG;
  }
  if (isDtgBanned && getCartProductTotalQuantity(cartProduct) < MINIMUM_DTG_QUANTITY) {
    return [CART_PRODUCT_WARNINGS.MINIMUM_QUANTITY_REQUIRED];
  }
  return [];
};

export const getCartProductErrors = (cartProduct: CartProduct): { code: CART_PRODUCT_ERRORS; data?: any }[] => {
  const errors = [];

  if (getCartProductTotalQuantity(cartProduct) === 0) {
    errors.push({ code: CART_PRODUCT_ERRORS.QUANTITY_REQUIRED });
  }

  if (getCartProductTotalQuantity(cartProduct) > 0 && isMinimumQuantityRequired(cartProduct)) {
    errors.push({ code: CART_PRODUCT_ERRORS.MINIMUM_QUANTITY_REQUIRED });
  }

  for (const quantity of Object.values(cartProduct.sizesQuantities)) {
    if (quantity > MAX_PRODUCT_QUANTITY) {
      errors.push({ code: CART_PRODUCT_ERRORS.MAXIMUM_QUANTITY_EXCEEDED });
      break;
    }
  }

  const { sizesOutOfStock } = getSizeInStockInfo(cartProduct);

  if (sizesOutOfStock.length) {
    errors.push({ code: CART_PRODUCT_ERRORS.ITEM_OUT_OF_STOCK });
  }

  return errors;
};

export const getSizeInStockInfo = (
  cartProduct: CartProduct,
  sizesQuantities?: SizesQuantities
): { sizesInStock: string[]; sizesOutOfStock: string[] } => {
  sizesQuantities = sizesQuantities || cartProduct.sizesQuantities;

  const colorId: string = cartProduct.colorId;
  const currentColor: ProductColor = cartProduct.product.colors.find((color: ProductColor) => {
    return color.name.toLowerCase() === colorId.toLowerCase();
  }) as ProductColor;
  const sizeObjects = currentColor.sizesObjects;

  const sizesOutOfStock =
    Object.keys(sizesQuantities).filter(
      (name) =>
        sizesQuantities &&
        sizeObjects &&
        sizesQuantities[name] > 0 &&
        sizeObjects.find((sizeObject) => {
          return sizeObject.name === name && !sizeObject.inStock;
        })
    ) || [];

  const sizesInStock =
    Object.keys(sizesQuantities).filter(
      (name) => sizesQuantities && sizesQuantities[name] > 0 && !sizesOutOfStock.includes(name)
    ) || [];

  return { sizesInStock, sizesOutOfStock };
};

export const getMockups = ({
  product,
  design,
  colorHex,
  mockupSize,
}: {
  product: Product;
  design: Design;
  colorHex: string;
  mockupSize: string;
}) => {
  const mockups = _.compact(
    design.sides.map((designSide) => {
      if (designSide.shapes.length === 0) return null;
      const designSideTemplate = product.designTemplates.find((designTemplate) => {
        return designTemplate.side === designSide.name;
      });
      if (!designSideTemplate) return null;
      return {
        side: DESIGN_SIDE_PRINT_SIDE_MAP[designSide.name],
        mockupUrl: url('mockup', mockupRoutes.mockup, {
          designId: design.id,
          templateIdentifier: designSideTemplate.id,
          color: colorHex,
          side: DESIGN_SIDE_PRINT_SIDE_MAP[designSide.name],
          size: mockupSize,
        }),
      };
    })
  );
  return mockups;
};

export const createNewCartItems = ({
  product,
  colorName,
  design,
  sizesQuantities,
}: {
  product: Product;
  colorName: string;
  design: Design;
  sizesQuantities: { name: string; qty: number }[];
}) => {
  const designId = design.id;

  const currentColor = product.colors.find(
    (productColor) => productColor.name.toLowerCase() === colorName.toLowerCase()
  ) as ProductColor;

  const designSideToDisplay = design.sides.find((designSide) => {
    return designSide.shapes.length > 0;
  });
  const designSideToDisplayKey: string = designSideToDisplay
    ? DESIGN_SIDE_ENUM[designSideToDisplay.name]
    : DESIGN_SIDE_ENUM.FRONT;

  const designTemplate = product.designTemplates.find((designTemplate) => {
    return designTemplate.side === designSideToDisplayKey;
  });
  const templateIdentifier: string = designTemplate ? designTemplate.id : '';
  const side: string = designSideToDisplayKey;
  const size: string = MOCKUP_SIZE;

  let mockupUrl: string = product.image.url;
  if (templateIdentifier) {
    mockupUrl = url('mockup', mockupRoutes.mockup, {
      designId,
      templateIdentifier,
      color: currentColor.hex,
      side,
      size,
    });
  }

  let newItems: TemporalCartItem[] = [];
  sizesQuantities.forEach((sizeObj: { name: string; qty: number }) => {
    if (currentColor.sizes.indexOf(sizeObj.name) === -1) return;
    const newItem = {
      designId,
      product: {
        productItemId: stringifyProductItemId(product.id, currentColor.name, sizeObj.name),
        quantity: sizeObj.qty > 0 ? sizeObj.qty : 0,
        mockupUrl,
        mockups: getMockups({ product, colorHex: currentColor.hex, design, mockupSize: MOCKUP_SIZE }),
      },
    };

    newItems.push(newItem);
  });

  return newItems;
};

export const calculateCartProductQuantity = (cart: Cart) => {
  const cartItems = cart.items.filter((item: CartItem) => {
    return item.product.quantity > 0;
  });
  const cartProductKeys = cartItems.map((cartItem: CartItem) => {
    const { productId, color } = parseProductItemId(cartItem.product.productItemId);
    return `${productId}-${color}-${cartItem.designId}`;
  });
  const cartQuantity = _.uniq(cartProductKeys).length;
  return cartQuantity;
};

export const reduceCoupons = (cartQuotes: RequestCompleteQuoteOutput[]) => {
  if (cartQuotes.length === 0) {
    return [];
  }
  const emptyCouponResponse = cartQuotes[0].couponResponse.map((_couponResponse) => {
    return {
      ..._couponResponse,
      discount: 0,
    };
  });
  const coupons = cartQuotes.reduce((totalCouponResponse, currentItemQuote: RequestCompleteQuoteOutput) => {
    const couponResponse = currentItemQuote.couponResponse;
    for (let i = 0; i < couponResponse.length; i++) {
      totalCouponResponse[i].discount += couponResponse[i].discount;
    }
    return totalCouponResponse;
  }, emptyCouponResponse);
  return coupons;
};

export const getCouponTotals = (cartQuotes: RequestCompleteQuoteOutput[]) => {
  const coupons = reduceCoupons(cartQuotes);

  const validCoupons = coupons.filter((coupon) => {
    return coupon.level === QUOTE_COUPON_RESPONSE_LEVEL_ENUM.SUCCESS;
  });

  const orderedCoupons = validCoupons.sort((coupon) => {
    return coupon.code === 'SHAREPROMO' ? -1 : 0;
  });

  return orderedCoupons;
};
