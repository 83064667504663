import { routes, path } from 'inkp-routes/public';
import { setupClient } from 'infra-frontend/helpers/apollo';

import DesignCartContainer from './states/global/designCart';

export default {
  uri: path(routes.publicGateway.gql),
  credentials: 'same-origin',
  subscribeTo: {
    designCart: DesignCartContainer, // The key will be the GraphQL query name
  },
};
