import { rotatedPosition, reversedRotatedPositions } from 'inkp-design-sdk/geometry';

import { FontCategory } from '../interfaces';
import { Shape, GuidelinePosition, TextShapeData } from '../interfaces/Canvas';

export const DEFAULT_FONT_CATEGORY: FontCategory = {
  name: 'popular',
};

export const DEFAULT_FONT_FAMILY = 'freshman';
export const DEFAULT_TEXT_COLOR_HEX = '#000000';

export const NEW_LINE_CHAR = '\n';
export const MIN_FONT_SIZE_PX = 10;
export const LINE_HEIGHT = 1.25;

export const getNumLines = (text: string) => {
  const textArray = normalizeText(text).split(NEW_LINE_CHAR);
  return textArray.length;
};

export const normalizeText = (text: string) => {
  if (!text || !text.length) return '';
  const newText = text.replace(/([\s]+$)|(^[\s]+)/g, '');
  return newText;
};

export const computeFontSize = (shape: TextShapeData) => {
  return Math.floor(shape.height / getNumLines(shape.text) / LINE_HEIGHT);
};

export const createGuideline = (
  printZone: {
    width: number;
    height: number;
    x: number;
    y: number;
  },
  position: GuidelinePosition = 'vertical-center'
) => {
  let start;
  let end;
  if (position === 'vertical-center') {
    start = { x: printZone.width / 2, y: printZone.y };
    end = { x: printZone.width / 2, y: printZone.y + printZone.height };
  } else {
    throw new Error(`Cannot create guideline for invalid position: ${position}`);
  }
  return {
    start,
    end,
  };
};

/**
 * Given shape, mousePosition, guideline, and threshold in pixels, and axis,
 * find closest vertical guideline to snap to
 * @returns {
 *   isSnappable - boolean to show whether shape is within guideline threshold
 *   shape - new shape and position to snap to
 * }
 */
export const snapToGuideline = (
  shape: Shape,
  position: {
    x: number;
    y: number;
  },
  guideline: {
    start: { x: number; y: number };
    end: { x: number; y: number };
  },
  threshold: number = 10, // Pixel allowance on either side of guideline, default to 10 pixels
  axis: 'x' | 'y' = 'x' // Specify the axis to snap to, default to 'x' axis (horizontal alignment)
) => {
  const rPosition = rotatedPosition(shape);
  const minX = Math.min(guideline.start.x, guideline.end.x);
  const maxX = Math.max(guideline.start.x, guideline.end.x);
  const minY = Math.min(guideline.start.y, guideline.end.y);
  const maxY = Math.max(guideline.start.y, guideline.end.y);
  const withinThresholdX = position.x >= minX - threshold && position.x <= maxX + threshold;
  const withinThresholdY = position.y >= minY - threshold && position.y <= maxY + threshold;

  let snapX;
  let snapY;
  let isSnappable = false;
  if (axis === 'x') {
    snapX = (minX + maxX) / 2 - rPosition.width / 2;
    snapY = rPosition.y;
    isSnappable = withinThresholdX;
  } else {
    snapX = rPosition.x;
    snapY = (minY + maxY) / 2 - rPosition.height / 2;
    isSnappable = withinThresholdY;
  }

  const { x, y } = reversedRotatedPositions(shape, {
    x: snapX,
    y: snapY,
    width: rPosition.width,
    height: rPosition.height,
  });

  return {
    isSnappable,
    shape: Object.assign({}, shape, { x, y }),
  };
};
