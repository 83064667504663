import { DataProxy } from 'apollo-cache';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Query as UserQueryResults,
  Mutation as UserMutationResults,
  MutationregisterArgs,
  MutationupdateUserArgs,
  User,
} from 'inkp-user-sdk/types.g';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    name
    organization
    phone
    role
    isInternal
    updatedAt
    signedUpAt
    createdAt
    ab {
      name
      value
    }
  }
`;

export class QueryCurrentUser extends Query<UserQueryResults> {}
export const QUERY_CURRENT_USER = gql`
  {
    currentUser {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export class MutationUpdateUser extends Mutation<UserMutationResults, MutationupdateUserArgs> {}
export const MUTATION_UPDATE_USER = gql`
  mutation UpdateUser($userData: UpdateUserData!) {
    updateUser(userData: $userData) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export class QueryUserByEmail extends Query<UserQueryResults> {}
export const QUERY_USER_BY_EMAIL = gql`
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export class MutationSignIn extends Mutation<UserMutationResults> {}
export const MUTATION_SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export class MutationSignOut extends Mutation<UserMutationResults> {}
export const MUTATION_SIGN_OUT = gql`
  mutation SignOut {
    signOut {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const MUTATION_REGISTER_USER = gql`
  mutation RegisterUser($userData: CreateUserData!) {
    register(userData: $userData) {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export class MutationRegisterUser extends Mutation<UserMutationResults, MutationregisterArgs> {}

export function updateCurrentUser(cache: DataProxy, data: User, query: any = QUERY_CURRENT_USER) {
  cache.writeQuery({
    query,
    data: { currentUser: data },
  });
}

export function getCurrentUser(cache: DataProxy) {
  const { currentUser }: any = cache.readQuery({ query: QUERY_CURRENT_USER });
  return currentUser;
}

export function signOutUser(cache: DataProxy, data: User, query: any = QUERY_CURRENT_USER) {
  const { currentUser }: any = cache.readQuery({ query });
  const newData = Object.assign({}, { ...currentUser }, { ...data });
  cache.writeQuery({
    query,
    data: { currentUser: newData },
  });
}
