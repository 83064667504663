import { DataProxy } from 'apollo-cache';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Query as ProductsQueryResults,
  Mutation as ProductsMutationResults,
  Product,
  QueryproductByIdArgs,
  QueryproductsByIdArgs,
} from 'inkp-product-sdk/types.g';

export const DESIGN_TEMPLATE_DATA_FRAGMENT = gql`
  fragment DesignTemplateDataFragment on DesignTemplateData {
    dimensions {
      width
      height
    }

    size {
      width
      height
    }

    bounds {
      topLeft {
        x
        y
      }
      topRight {
        x
        y
      }
      bottomLeft {
        x
        y
      }
      bottomRight {
        x
        y
      }
    }
  }
`;

export const GENERAL_PRODUCT_FRAGMENT = gql`
  fragment GeneralProductFragment on Product {
    id
    additionalImages {
      label
      url
    }
    brand
    categories {
      name
      scope
    }
    categoryIdentifier
    colors {
      class
      enabled
      heather
      hex
      images {
        label
        url
      }
      inStock
      name
      printTypes {
        SCREENPRINT
        DTG
      }
      productId
      sizes
      sizesObjects {
        id
        inStock
        name
        price
        speed
      }
      startingPrice
    }
    comments
    createdAt
    deletedAt
    description
    detailTemplates {
      id
      side
    }
    designTemplates {
      side
      id
      data {
        ...DesignTemplateDataFragment
      }
    }
    enabled
    image {
      label
      url
    }
    inStock
    material
    name
    printTypes {
      SCREENPRINT
      DTG
    }
    rank
    relatedLabels
    sides {
      name
      printZones
      printZonesObjects {
        id
        name
        position {
          top
          bottom
          right
          left
        }
        size {
          width
          height
        }
      }
    }
    sla
    status
    styleId
    tags {
      material
      collar
      brand
      sleeve
      type
      gender
      weave
      feature
    }
    type
    updatedAt
  }
  ${DESIGN_TEMPLATE_DATA_FRAGMENT}
`;

export const QUERY_PRODUCT_BY_ID = gql`
  query ProductById($id: String!) {
    productById(id: $id) {
      ...GeneralProductFragment
    }
  }

  ${GENERAL_PRODUCT_FRAGMENT}
`;

export class QueryProductById extends Query<ProductsQueryResults, QueryproductByIdArgs> {}

export const QUERY_PRODUCTS_BY_ID = gql`
  query ProductsById($ids: [String!]!) {
    productsById(ids: $ids) {
      ...GeneralProductFragment
    }
  }

  ${GENERAL_PRODUCT_FRAGMENT}
`;

export class QueryProductsById extends Query<ProductsQueryResults, QueryproductsByIdArgs> {}

export const getProductById = (cache: DataProxy, productId: string, query: any = QUERY_PRODUCT_BY_ID) => {
  const { productById }: any = cache.readQuery({ query, variables: { id: productId } });
  return productById;
};