import 'core-js/features/array/flat';
import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import loadable from '@loadable/component';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

import env from 'infra-util/env';
import { routes } from 'inkp-routes/public';
import config from 'inkp-config';
import Loading from 'inkp-components/dist/Components/Loading';

import { QueryCurrentUser, QUERY_CURRENT_USER } from './graphql/users';
import { isTouchSupported } from './util/touch';
import AbTest, { AbVariation } from './components/HOC/AbTest';

let hot;

if (env === 'development') {
  hot = require('react-hot-loader').hot;
} else {
  hot = () => (n: any) => n;
}

// tslint:disable-next-line: space-in-parens
const HomePage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/HomePage')));
// tslint:disable-next-line: space-in-parens
const AboutPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/About')));
// tslint:disable-next-line: space-in-parens
const ProductSelectionPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/ProductSelection')));
// tslint:disable-next-line: space-in-parens
const ProductDetailPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/ProductDetail')));
// tslint:disable-next-line: space-in-parens
const RegisterPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/RegisterPage')));
// tslint:disable-next-line: space-in-parens
const SignInPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/SignInPage')));
// tslint:disable-next-line: space-in-parens
const ForgotPasswordPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/ForgotPasswordPage')));
// tslint:disable-next-line: space-in-parens
const ResetPasswordPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/ResetPasswordPage')));
// tslint:disable-next-line: space-in-parens
const ArtworkApprovalPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/ArtworkApproval')));
// tslint:disable-next-line: space-in-parens
const DesignToolPage = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/DesignToolPage')));
// tslint:disable-next-line: space-in-parens
const EditDesign = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/EditDesign')));
// tslint:disable-next-line: space-in-parens
const EditCartDesign = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/EditCartDesign')));
// tslint:disable-next-line: space-in-parens
const Share = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/Share')));
// tslint:disable-next-line: space-in-parens
const Redesign = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/Redesign')));
// tslint:disable-next-line: space-in-parens
const CartCheckout = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/CartCheckout')));
// tslint:disable-next-line: space-in-parens
const DesignRender = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/DesignRender')));
// tslint:disable-next-line: space-in-parens
const RetrieveDesign = loadable(() => import(/* webpackPrefetch: true */ './pages/RetrieveDesign'));
// tslint:disable-next-line: space-in-parens
const TrackOrder = loadable(() => import(/* webpackPrefetch: true */ './pages/TrackOrder'));
// tslint:disable-next-line: space-in-parens
const MyAccount = loadable(() => import(/* webpackPrefetch: true */ './pages/MyAccount'));
// tslint:disable-next-line: space-in-parens
const HelpFaq = loadable(() => import(/* webpackPrefetch: true */ './pages/HelpFaq'));
// tslint:disable-next-line: space-in-parens
const PrivacyPolicy = loadable(() => import(/* webpackPrefetch: true */ './pages/PrivacyPolicy'));
// tslint:disable-next-line: space-in-parens
const TermsOfService = loadable(() => import(/* webpackPrefetch: true */ './pages/TermsOfService'));
// tslint:disable-next-line: space-in-parens
const PageNotFound = loadable(() => import(/* webpackPrefetch: true */ './pages/PageNotFound'));
// tslint:disable-next-line: space-in-parens
const ForceAb = loadable(() => import(/* webpackPrefetch: true */ './pages/ForceAb'));

// OrderPages
// tslint:disable-next-line: space-in-parens
const OrderConfirmation = withRouter(
  loadable(() => import(/* webpackPrefetch: true */ './pages/Order/OrderConfirmation'))
);
// tslint:disable-next-line: space-in-parens
const GetOrderById = withRouter(loadable(() => import(/* webpackPrefetch: true */ './pages/Order/GetOrderById')));
// tslint:disable-next-line: space-in-parens
const OrderInvoice = loadable(() => import(/* webpackPrefetch: true */ './pages/Order/Invoice'));

// Layouts
// tslint:disable-next-line: space-in-parens
const MainLayout = loadable(() => import(/* webpackPrefetch: true */ './layout/main'));
// tslint:disable-next-line: space-in-parens
const CheckoutLayout = loadable(() => import(/* webpackPrefetch: true */ './layout/checkout'));
// tslint:disable-next-line: max-line-length
// @ts-ignore browser touch specific
const touchSupported = isTouchSupported();
const DndBackend = touchSupported ? TouchBackend : HTML5Backend;

const COVI19_BLOB = `Due to closures caused by COVID-19, we are unfortunately unable to process new orders until further notice.`;

const Covid19Banner: React.FunctionComponent = ({ children }) => {
  return (
    <>
      <div className="w-full bgc-purple ta-center color-white fw-bold py-p5 px-1">{COVI19_BLOB}</div>
      {children}
    </>
  );
};

class App extends React.Component {
  render() {
    return (
      <DragDropContextProvider backend={DndBackend}>
        <QueryCurrentUser query={QUERY_CURRENT_USER}>
          {({ loading: loadingUser, error: userLoadError, data: userData }: any) => {
            if (loadingUser || !userData) {
              return <Loading />;
            }
            if (userLoadError || !userData.currentUser) {
              console.error('User Load Error');
              throw new Error('User Load Error');
            }

            const { currentUser } = userData;

            return (
              <div id="app" className="w-full h-full">
                {/* Using react helmet to populate header tags */}
                <Helmet>
                  <meta charSet="utf-8" />
                  <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                  <meta
                    name="viewport"
                    content="initial-scale=1.0,user-scalable=no,maximum-scale=1,width=device-width"
                  />
                  <meta name="HandheldFriendly" content="True" />
                  <meta name="format-detection" content="telephone=no" />
                  <meta name="apple-mobile-web-app-capable" content="yes" />
                  <meta httpEquiv="cleartype" content="on" />
                  <meta name="google-site-verification" content="xTV-jd-hPt-Z2Jc4k6JkgSSDPzNhKkRmMxmGklNS11E" />
                  <meta name="p:domain_verify" content={`${config.pinterest.verificationTagNumber}`} />
                  <link
                    href="https://inkp-production.32pt.com/public/fonts/gilroy.css"
                    rel="stylesheet"
                    type="text/css"
                  />
                  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
                </Helmet>
                <Switch>
                  <Route
                    exact
                    path={routes.app.base}
                    render={(props: any) => {
                      return (
                        <Covid19Banner>
                          <MainLayout {...props}>
                            <HomePage {...props} />
                          </MainLayout>
                        </Covid19Banner>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.about}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <AboutPage {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.product.category}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <ProductSelectionPage {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.product.base}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <ProductSelectionPage {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.product.detail}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <ProductDetailPage {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.account.register}
                    render={(props: any) => <RegisterPage {...props} />}
                  />
                  <Route exact path={routes.app.account.signIn} render={(props: any) => <SignInPage {...props} />} />
                  <Route
                    exact
                    path={routes.app.account.artworkApproval}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <ArtworkApprovalPage {...props} />
                        </MainLayout>
                      );
                    }}
                  />

                  <Route
                    exact
                    path={routes.app.account.forgotPassword}
                    render={(props: any) => <ForgotPasswordPage {...props} />}
                  />
                  <Route
                    exact
                    path={routes.app.account.resetPassword}
                    render={(props: any) => <ResetPasswordPage {...props} />}
                  />
                  <Route
                    exact
                    path={routes.app.designTool}
                    render={(props: any) => <DesignToolPage {...props} currentUser={currentUser} />}
                  />
                  <Route exact path={routes.app.share} render={(props: any) => <Share {...props} />} />
                  <Route
                    exact
                    path={routes.app.checkout.cart}
                    render={(props: any) => {
                      return (
                        <Covid19Banner>
                          <MainLayout
                            showProductsMenu={false}
                            showWhyInkpopMenu={false}
                            showHelpMenu={false}
                            showSpecialistPhoneMenu={false}
                            showStartDesigningButton={false}
                            {...props}
                          >
                            <CartCheckout {...props} />
                          </MainLayout>
                        </Covid19Banner>
                      );
                    }}
                  />
                  {/* <Route
                    exact
                    path={routes.app.checkout.base}
                    render={(props: any) => {
                      return (
                        <Covid19Banner>
                          <CartCheckout {...props} />;
                        </Covid19Banner>
                      );
                    }}
                  /> */}
                  <Route
                    exact
                    path={routes.app.order.confirmation}
                    render={(props: any) => {
                      return (
                        <CheckoutLayout title="order confirmation" {...props}>
                          <OrderConfirmation {...props} />
                        </CheckoutLayout>
                      );
                    }}
                  />
                  <Route exact path={routes.app.render} render={(props: any) => <DesignRender {...props} />} />
                  <Route exact path={routes.app.redesign} render={(props: any) => <Redesign {...props} />} />
                  <Route
                    exact
                    path={routes.app.account.retrieveDesign}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <RetrieveDesign {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.account.trackOrder}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <TrackOrder {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.account.myAccount}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <MyAccount {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.help}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <HelpFaq {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.privacyPolicy}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <PrivacyPolicy {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.termsOfService}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <TermsOfService {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.order.getById}
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <GetOrderById {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.order.invoice}
                    render={(props: any) => {
                      return <OrderInvoice {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path={routes.app.account.editDesign}
                    render={(props: any) => <EditDesign {...props} />}
                  />
                  <Route
                    exact
                    path={routes.app.checkout.editCartDesign}
                    render={(props: any) => <EditCartDesign {...props} />}
                  />
                  <Route exact path={routes.app.secret.forceAb} render={(props: any) => <ForceAb {...props} />} />
                  <Route
                    render={(props: any) => {
                      return (
                        <MainLayout {...props}>
                          <PageNotFound {...props} />
                        </MainLayout>
                      );
                    }}
                  />
                </Switch>
              </div>
            );
          }}
        </QueryCurrentUser>
      </DragDropContextProvider>
    );
  }
}

export default hot(module)(App);
