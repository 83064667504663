import _ from 'lodash';
import gql from 'graphql-tag';
import moment from 'oo-moment';
import * as Sentry from '@sentry/browser';
import { DataProxy } from 'apollo-cache';
import config from 'inkp-config';
import { url } from 'inkp-routes/public';
import mockupRoutes from 'inkp-mockup-sdk/routes';
import { User } from 'inkp-user-sdk/types.g';
import { Product, ProductCategory, ProductColor, ProductImage, DesignTemplate } from 'inkp-product-sdk/types.g';
import {
  Cart,
  CartItem,
  RequestCompleteQuoteOutput,
  Amounts,
  Order,
  OrderFeatures,
  OrderQuote,
  QuoteCouponResponse,
} from 'inkp-order-sdk/types.g';
import { Design, Shareable, DesignSide, DESIGN_SIDE_ENUM } from 'inkp-design-sdk/types.g';
import { parseProductItemId, DEFAULT_MOCKUP_SIZE } from 'inkp-product-sdk';
import client from '../apollo';
import {
  GTMAddressBlock,
  GTMCart,
  GTMCartItem,
  GTMData,
  GTMEvent,
  GTMEvents,
  GTMItem,
  GTMOrder,
  GTMTypes,
  GTMUser,
} from '../interfaces/GTM';
import { getFreeShipDate, getFastShipDate, getFastestShipDate } from './shippingDates';
import { getProductDetailsImages, getProductDetailsLink, getProductColorId } from './Product';

import { getCurrentUser } from './login';
import {
  formatPrice,
  getCartAmounts,
  getCartByUser,
  getCartQuoteByCartId,
  getProductsById,
  findUserCoupon,
} from './Checkout';
import { getOrderById } from './orders';
import * as GTM_ERRORS from '../util/errors/gtm';

// GraphQL
import { getDesignById } from '../graphql/designs';
import { getProductById } from '../graphql/products';

const IS_INTERNAL_REGEXP: RegExp = /^.+@scalablepress\.com$/;
const PRODUCT_CATEGORY_SCOPE_LABEL: string = 'type';
const T_SHIRTS_CATEGORY_SCOPE_LABEL: string = 't-shirts';
const T_SHIRTS_SUB_CATEGORY_SCOPE_LABEL: string = 'sleeve';
const SWEATSHIRTS_CATEGORY_SCOPE_LABEL: string = 'sweatshirts';
const FRONT_SIDE: string = 'front';
const BACK_SIDE: string = 'back';
const LEFT_SIDE: string = 'left';
const RIGHT_SIDE: string = 'right';
const ITEMS_NUMBER_FOR_STARTING_PRICE: number = 50;

interface CartItemWithDesign extends CartItem {
  design: Design;
}

const QUERY_CART_BY_USER_FOR_GTM = gql`
  query Cart {
    cartByUser {
      items {
        designId
        printType
        product {
          productItemId
          quantity
          mockupUrl
          mockups {
            side
            mockupUrl
          }
        }
      }
    }
  }
`;

const QUERY_PRODUCT_BY_ID_FOR_GTM = gql`
  query ProductById($id: String!) {
    productById(id: $id) {
      id
      brand
      categories {
        name
        scope
      }
      colors {
        class
        enabled
        heather
        hex
        images {
          label
          url
        }
        inStock
        name
        printTypes {
          SCREENPRINT
          DTG
        }
        productId
        sizes
        sizesObjects {
          id
          inStock
          name
          price
          speed
        }
        startingPrice
      }
      detailTemplates {
        id
        side
      }
    }
  }
`;

const getProductSubCategoryForType = (product: Product, categoryType: ProductCategory): ProductCategory | undefined => {
  if (!categoryType) {
    return;
  }
  const type: string = categoryType.name;
  let subCategory: ProductCategory | undefined;
  switch (type) {
    case T_SHIRTS_CATEGORY_SCOPE_LABEL: {
      subCategory = product.categories.find((category: ProductCategory) => {
        return category.scope.toLowerCase() === T_SHIRTS_SUB_CATEGORY_SCOPE_LABEL;
      });
      break;
    }
    case SWEATSHIRTS_CATEGORY_SCOPE_LABEL: {
      subCategory = product.categories.find((category: ProductCategory) => {
        return (
          category.scope.toLowerCase() === 'type' && category.name.toLowerCase() !== SWEATSHIRTS_CATEGORY_SCOPE_LABEL
        );
      });
      break;
    }
  }
  return subCategory;
};

const getProductCategory = (product: Product): ProductCategory | undefined => {
  return product.categories.find((category: ProductCategory) => {
    return category.scope === 'type';
  });
};

const pushToGTM = (gtmObject: GTMData | GTMEvent) => {
  const dataLayer: any[] = (global as any)[config.gtm.dataLayerName];
  if (dataLayer) {
    return dataLayer.push(gtmObject);
  }
};

const buildGTMUser = (
  cache: DataProxy,
  data: {
    designEmail: string;
  }
): GTMUser => {
  const { designEmail } = data;
  const currentUser: User = getCurrentUser(cache);
  const freeShipDate: string = getFreeShipDate();
  const fastShipDate: string = getFastShipDate();
  const fastestShipDate: string = getFastestShipDate();
  const gtmUser: GTMUser = {
    name: currentUser.name || null,
    userId: currentUser.id,
    emailAddress: currentUser.email || designEmail || null,
    isInternal: currentUser.isInternal || false,
    accountCreationDate: currentUser.signedUpAt || null,
    freeShipDate,
    fastShipDate,
    fastestShipDate,
  };
  return gtmUser;
};

const buildGTMItem = (
  cache: DataProxy,
  data: {
    productId: string;
    colorName: string;
    designId: string;
    product?: Product;
    design?: Design;
    mockupUrl?: string;
    itemDesignEmail?: string;
    cartId?: string;
  }
): GTMItem => {
  let gtmItem: GTMItem;
  const { productId, colorName, designId, mockupUrl, itemDesignEmail, cartId } = data;
  // if we received the Product object we use that, otherwise, we query for the Product using ProductId
  let product: Product;
  if (data.product) {
    product = Object.assign({}, data.product);
  } else if (designId) {
    product = getProductById(cache, productId);
  } else {
    product = getProductById(cache, productId, QUERY_PRODUCT_BY_ID_FOR_GTM);
  }
  const activeColor: ProductColor =
    product.colors.find((color: ProductColor) => {
      return color.name.toLowerCase() === colorName.toLowerCase();
    }) || product.colors[0];
  const itemProductTypeParent: ProductCategory | undefined = getProductCategory(product);
  const itemProductType: ProductCategory | undefined = getProductSubCategoryForType(
    product,
    itemProductTypeParent as ProductCategory
  );
  const itemProductId: string = product.id;
  const colorId: string = getProductColorId(activeColor);
  const itemColorName: string = activeColor.name;
  const itemColorHex: string = activeColor.hex;
  const itemBrand: string = product.brand;
  const itemPrice: string = `${formatPrice(activeColor.startingPrice / ITEMS_NUMBER_FOR_STARTING_PRICE)}`;
  const itemMSRP: string = `${formatPrice(0)}`;
  const productDetailImages: ProductImage[] = getProductDetailsImages(product, activeColor);
  const productDetailImage: ProductImage | undefined = productDetailImages.find((productDetailImage) => {
    return productDetailImage.label.toLowerCase() === FRONT_SIDE;
  });
  const itemImage: string = productDetailImage ? productDetailImage.url : '';
  const itemLink: string = getProductDetailsLink(product);

  gtmItem = {
    itemProductTypeParent: itemProductTypeParent ? itemProductTypeParent.name : '',
    itemProductType: itemProductType ? itemProductType.name : '',
    itemProductId: `${itemProductId}_${colorId}`, // this is the way we agreed with mkt to send this data
    itemBrand,
    itemPrice,
    itemMSRP,
    itemImage,
    itemLink,
    itemColorName,
    itemColorHex,
  };

  let cartQuote: RequestCompleteQuoteOutput[] = [];
  if (cartId) {
    try {
      cartQuote = getCartQuoteByCartId(cache, cartId);
    } catch (err) {
      console.warn(err);
      Sentry.captureMessage(`${GTM_ERRORS.CART_QUOTE_NOT_FOUND_IN_APOLLO_CACHE} ${cartId}`);
      Sentry.captureException(err);
    }
  }

  if (designId) {
    try {
      const design: Design = data.design ? data.design : getDesignById(cache, designId);
      const shareable: Shareable | undefined = design.shareable;
      const itemDesignId: string = design.id;
      const itemDesignName: string = shareable ? shareable.name : '';
      const itemDesignCreatedDate: string = `${design.createdAt}`;
      const itemDesignLastUpdateDate: string = `${design.updatedAt}`;

      let itemDesignProductImageFront: string = '';
      let itemDesignProductImageBack: string = '';
      let itemDesignProductImageLeft: string = '';
      let itemDesignProductImageRight: string = '';

      design.sides
        .filter((side: DesignSide) => {
          return side.shapes.length > 0;
        })
        .forEach((side: DesignSide) => {
          const sideName: DESIGN_SIDE_ENUM = side.name;
          const designTemplate: DesignTemplate | undefined = product.designTemplates.find(
            (designTemplate: DesignTemplate) => {
              return designTemplate.side === sideName;
            }
          );
          if (designTemplate) {
            const mockupUrl = url('mockup', mockupRoutes.mockup, {
              designId: design.id,
              templateIdentifier: designTemplate.id,
              color: itemColorHex,
              side: sideName,
              size: DEFAULT_MOCKUP_SIZE,
            });
            switch (sideName) {
              case DESIGN_SIDE_ENUM.FRONT: {
                itemDesignProductImageFront = mockupUrl;
                break;
              }
              case DESIGN_SIDE_ENUM.BACK: {
                itemDesignProductImageBack = mockupUrl;
                break;
              }
              case DESIGN_SIDE_ENUM.LEFT: {
                itemDesignProductImageLeft = mockupUrl;
                break;
              }
              case DESIGN_SIDE_ENUM.RIGHT: {
                itemDesignProductImageRight = mockupUrl;
                break;
              }
            }
          }
        });
      const itemDesignIsBlank: boolean =
        !itemDesignProductImageFront &&
        !itemDesignProductImageBack &&
        !itemDesignProductImageLeft &&
        !itemDesignProductImageRight;

      let itemDesignPrintType: string = '';
      try {
        const cart: Cart = getCartByUser(cache, QUERY_CART_BY_USER_FOR_GTM);
        const cartItems: CartItem[] = cart.items;
        const currentCartItem: CartItem | undefined = cartItems.find((cartItem: CartItem) => {
          const { product } = cartItem;
          const parsedProductItem = parseProductItemId(product.productItemId);
          return (
            cartItem.designId === designId &&
            parsedProductItem.productId === productId &&
            parsedProductItem.color === colorName
          );
        });
        itemDesignPrintType = currentCartItem ? currentCartItem.printType : '';
      } catch (err) {
        console.warn(err);
        Sentry.captureMessage(GTM_ERRORS.USERS_CART_NOT_FOUND_IN_APOLLO_CACHE);
        Sentry.captureException(err);
      }

      // TODO: get color count
      const itemDesignColorCountFront: string = '';
      const itemDesignColorCountBack: string = '';
      const itemDesignColorCountLeft: string = '';
      const itemDesignColorCountRight: string = '';

      const itemDesignShareableId: string = shareable ? shareable.code : '';

      let itemPriceWithPrint: number = 0;
      let itemProductQty: number = 0;

      if (cartQuote.length > 0) {
        const cartItemQuotes: RequestCompleteQuoteOutput[] = cartQuote.filter((cartItemQuote) => {
          const colorId: string = itemColorName.toLowerCase().replace(/\s/gi, '-');
          const cartProductItemId: string = `${itemProductId}_${colorId}`;
          return (
            cartItemQuote.designId === itemDesignId &&
            cartItemQuote.product.productItemId.match(cartProductItemId) !== null
          );
        });
        cartItemQuotes.forEach((cartItemQuote) => {
          const {
            product: { quantity },
            amounts: { subtotal },
          } = cartItemQuote;
          itemPriceWithPrint += subtotal;
          itemProductQty += quantity;
        });
      }

      if (itemPriceWithPrint) {
        gtmItem = {
          ...gtmItem,
          itemPrice: formatPrice(itemPriceWithPrint),
          itemProductQty: `${itemProductQty}`,
        };
      }

      gtmItem = {
        ...gtmItem,
        itemDesignId,
        itemDesignName,
        itemDesignProductImageFront,
        itemDesignProductImageBack,
        itemDesignProductImageLeft,
        itemDesignProductImageRight,
        itemDesignColorCountFront,
        itemDesignColorCountBack,
        itemDesignColorCountLeft,
        itemDesignColorCountRight,
        itemDesignIsBlank,
        itemDesignPrintType,
        itemDesignCreatedDate,
        itemDesignLastUpdateDate,
        itemDesignEmail,
        itemDesignShareableId,
      };
    } catch (err) {
      console.warn(err);
      Sentry.captureMessage(`${GTM_ERRORS.DESIGN_NOT_FOUND_IN_APOLLO_CACHE} ${designId}`);
      Sentry.captureException(err);
    }
  }

  return gtmItem;
};

const buildGTMAddressBlock = (cache: DataProxy): GTMAddressBlock => {
  const cart: Cart = getCartByUser(cache);
  const { billingAddress, shippingAddress } = cart;
  let billingName: string = '';
  let billingCompanyName: string = '';
  let billingAddress1: string = '';
  let billingAddress2: string = '';
  let billingCity: string = '';
  let billingState: string = '';
  let billingZip: string = '';
  let billingCountry: string = '';
  let shippingName: string = '';
  let shippingCompanyName: string = '';
  let shippingAddress1: string = '';
  let shippingAddress2: string = '';
  let shippingCity: string = '';
  let shippingZip: string = '';
  let shippingState: string = '';
  let shippingCountry: string = '';

  if (billingAddress) {
    billingName = billingAddress.name || '';
    billingCompanyName = billingAddress.company || '';
    billingAddress1 = billingAddress.address1 || '';
    billingAddress2 = billingAddress.address2 || '';
    billingCity = billingAddress.city || '';
    billingState = billingAddress.state || '';
    billingZip = billingAddress.zip || '';
    billingCountry = billingAddress.country || '';
  }

  if (shippingAddress) {
    shippingName = shippingAddress.name || '';
    shippingCompanyName = shippingAddress.company || '';
    shippingAddress1 = shippingAddress.address1 || '';
    shippingAddress2 = shippingAddress.address2 || '';
    shippingCity = shippingAddress.city || '';
    shippingState = shippingAddress.state || '';
    shippingZip = shippingAddress.zip || '';
    shippingCountry = shippingAddress.country || '';
  }
  return {
    billingName,
    billingCompanyName,
    billingAddress1,
    billingAddress2,
    billingCity,
    billingState,
    billingZip,
    billingCountry,
    shippingName,
    shippingCompanyName,
    shippingAddress1,
    shippingAddress2,
    shippingCity,
    shippingZip,
    shippingState,
    shippingCountry,
  };
};

const buildGTMCartItem = (cartItem: CartItemWithDesign, amounts: Amounts): GTMCartItem => {
  const {
    product: { mockupUrl, product, productItem, productItemId, quantity },
    design: { shareable },
  } = cartItem;
  const { productId, color: colorName, size } = parseProductItemId(productItemId);
  const cartItemDesignId: string = cartItem.designId;
  const cartItemDesignShareableId: string = shareable && shareable.code ? shareable.code : '';
  const cartItemProductTypeParent: ProductCategory | undefined = getProductCategory(product as Product);
  const cartItemProductType: ProductCategory | undefined = getProductSubCategoryForType(
    product as Product,
    cartItemProductTypeParent as ProductCategory
  );
  const activeColor: ProductColor | undefined = (product as Product).colors.find((color: ProductColor) => {
    return color.name.toLowerCase() === colorName;
  });
  // this might never happens but for safety, lets have a default empty value
  const colorId: string = activeColor ? getProductColorId(activeColor) : '';
  const cartItemProductId: string = `${productId}_${colorId}`;
  const cartItemProductBrand: string = product ? (product as Product).brand : '';
  const cartItemProductQty: number = quantity;
  const cartItemProductSize: string = size;
  const productDetailImages: ProductImage[] = getProductDetailsImages(product as Product, activeColor as ProductColor);
  const cartItemProductImageFront: ProductImage | undefined = productDetailImages.find((productDetailImage) => {
    return productDetailImage.label.toLowerCase() === FRONT_SIDE;
  });
  const cartItemProductImageBack: ProductImage | undefined = productDetailImages.find((productDetailImage) => {
    return productDetailImage.label.toLowerCase() === BACK_SIDE;
  });
  const cartItemProductImageLeft: ProductImage | undefined = productDetailImages.find((productDetailImage) => {
    return productDetailImage.label.toLowerCase() === LEFT_SIDE;
  });
  const cartItemProductImageRight: ProductImage | undefined = productDetailImages.find((productDetailImage) => {
    return productDetailImage.label.toLowerCase() === RIGHT_SIDE;
  });
  const cartItemColorName: string = activeColor ? activeColor.name : '';
  const cartItemColorHex: string = activeColor ? activeColor.hex : '';
  const cartItemMSRP: string = `${formatPrice(0)}`;
  const cartItemPrice: string = `${formatPrice(amounts.total)}`;

  let cartItemProductImageFrontLink: string = '';
  if (mockupUrl) {
    cartItemProductImageFrontLink = mockupUrl;
  }

  return {
    cartItemDesignId,
    cartItemProductTypeParent: cartItemProductTypeParent ? cartItemProductTypeParent.name : '',
    cartItemProductType: cartItemProductType ? cartItemProductType.name : '',
    cartItemProductId,
    cartItemProductBrand,
    cartItemProductQty,
    cartItemProductSize,
    cartItemProductImageFront: cartItemProductImageFrontLink,
    cartItemProductImageBack: '',
    cartItemProductImageLeft: '',
    cartItemProductImageRight: '',
    cartItemColorName,
    cartItemColorHex,
    cartItemMSRP,
    cartItemPrice,
    cartItemDesignShareableId,
  };
};

const buildGTMCart = (cache: DataProxy): GTMCart => {
  const cart: Cart = getCartByUser(cache);
  const productIds = _.uniq(
    _.compact(
      _.map(cart.items, (cartItem: CartItem) => {
        const { productId } = parseProductItemId(cartItem.product.productItemId);
        return productId;
      })
    )
  );
  const products: Product[] = productIds.length > 0 ? getProductsById(cache, productIds) : [];
  const items: Partial<CartItemWithDesign>[] = cart.items.map((cartItem: CartItem) => {
    const {
      designId,
      product: { productItemId },
      design,
    } = cartItem;
    const { productId } = parseProductItemId(productItemId);
    const product: Product | undefined = products.find((product: Product) => {
      return product.id === productId;
    });
    return {
      designId,
      product: {
        ...cartItem.product,
        product,
      },
      design: design as Design,
    };
  });
  let cartQuote: RequestCompleteQuoteOutput[];
  let cartSubtotal: string = `${formatPrice(0)}`;
  if (cart.items.length > 0) {
    cartQuote = getCartQuoteByCartId(cache, cart.id);
    const cartAmounts: Amounts = getCartAmounts(cartQuote);
    cartSubtotal = `${formatPrice(cartAmounts.subtotal)}`;
  }

  const totalQuantity: number = items.reduce((total: number, currentCartItem: CartItemWithDesign) => {
    const {
      product: { quantity },
    } = currentCartItem;
    return total + quantity;
  }, 0);
  const totalLineItems: number = items.length;
  const cartItemsDesigns: string[] = items.map((cartItem: CartItemWithDesign) => {
    return cartItem.designId;
  });
  const totalDesigns: number = _.uniq(cartItemsDesigns).length;
  const cartItems: GTMCartItem[] = items.map((cartItem: CartItemWithDesign, index: number) => {
    return buildGTMCartItem(cartItem, cartQuote[index].amounts);
  });
  return {
    cartSubtotal,
    totalQuantity,
    totalLineItems,
    totalDesigns,
    cartItems,
  };
};

const buildGTMOrder = (cache: DataProxy, orderId: string): GTMOrder => {
  const gtmAddressBlock: GTMAddressBlock = buildGTMAddressBlock(cache);
  const order: Order = getOrderById(cache, orderId);
  const cart: Cart = getCartByUser(cache);
  const cartQuote: RequestCompleteQuoteOutput[] = getCartQuoteByCartId(cache, cart.id);
  const quote: OrderQuote = order.quote;
  const { amounts, shipping } = quote;
  const features: OrderFeatures | undefined = order.features;
  const orderNumber: string = order.id;
  const orderSubtotal: string = `${formatPrice(amounts.subtotal)}`;
  const orderGrandTotal: string = `${formatPrice(amounts.total)}`;
  const estDeliveryDate: string = order.dueAt
    ? moment(order.dueAt)
        .add(10, 'days') // added 10 days due to Covid-19 delays.
        .format('YYYY-MM-DD')
    : '';
  const shippingSpeed: string = shipping.speed;
  let proofRequested: boolean;
  if (features && (features.requiresCustomerApproval !== undefined || features.requiresCustomerApproval !== null)) {
    proofRequested = !!features.requiresCustomerApproval;
  } else {
    proofRequested = false;
  }
  const userCoupon: QuoteCouponResponse | undefined = findUserCoupon(cartQuote);
  const couponCode: string = userCoupon ? userCoupon.code : '';
  return {
    orderNumber,
    orderSubtotal,
    orderGrandTotal,
    estDeliveryDate,
    shippingSpeed,
    proofRequested,
    couponCode,
    ...gtmAddressBlock,
  };
};

class GTM {
  static apolloClient: any;

  static isInitialized(): Boolean {
    return !!GTM.apolloClient;
  }

  static fire(event: GTMEvents, eventData?: any) {
    if (!GTM.isInitialized()) return;

    let gtmEvent: GTMEvent | undefined = undefined;

    switch (event) {
      case GTMEvents.ACCOUNT_SIGNUP: {
        gtmEvent = {
          event: GTMEvents.ACCOUNT_SIGNUP,
        };
        break;
      }
      case GTMEvents.PURCHASE: {
        gtmEvent = {
          event: GTMEvents.PURCHASE,
        };
        break;
      }
      case GTMEvents.INITIATE_CHECKOUT: {
        gtmEvent = {
          event: GTMEvents.INITIATE_CHECKOUT,
        };
        break;
      }
      case GTMEvents.ADD_TO_CART: {
        gtmEvent = {
          event: GTMEvents.ADD_TO_CART,
        };
        break;
      }
      case GTMEvents.VIEW_PRODUCT: {
        gtmEvent = {
          event: GTMEvents.VIEW_PRODUCT,
        };
        break;
      }
      case GTMEvents.SAVE_DESIGN: {
        gtmEvent = {
          event: GTMEvents.SAVE_DESIGN,
        };
        break;
      }
      case GTMEvents.START_DESIGN: {
        gtmEvent = {
          event: GTMEvents.START_DESIGN,
        };
        break;
      }
      case GTMEvents.SEARCH_CLIPART: {
        gtmEvent = {
          event: GTMEvents.SEARCH_CLIPART,
        };
        break;
      }
    }
    if (gtmEvent) {
      gtmEvent = { ...gtmEvent, eventData };
      pushToGTM(gtmEvent);
    }
  }

  static push(type: GTMTypes, data?: any) {
    if (!GTM.isInitialized()) return;

    const cache: DataProxy = GTM.apolloClient.cache;
    let gtmData: GTMData | undefined = undefined;
    try {
      switch (type) {
        case GTMTypes.USER: {
          const designEmail = data ? data.designEmail : '';
          gtmData = {
            User: buildGTMUser(cache, { designEmail }),
          };
          break;
        }
        case GTMTypes.ITEM: {
          const { productId, designId, colorName, product, design, mockupUrl, itemDesignEmail, cartId } = data;
          pushToGTM({ Item: null });
          gtmData = {
            Item: buildGTMItem(cache, {
              productId,
              designId,
              colorName,
              product,
              design,
              mockupUrl,
              itemDesignEmail,
              cartId,
            }),
          };
          break;
        }
        case GTMTypes.ADDRESS: {
          const { userId } = data;
          gtmData = {
            AddressBlock: buildGTMAddressBlock(cache),
          };
          break;
        }
        case GTMTypes.CART: {
          gtmData = {
            Cart: buildGTMCart(cache),
          };
          break;
        }
        case GTMTypes.ORDER: {
          const { orderId } = data;
          gtmData = {
            Order: buildGTMOrder(cache, orderId),
          };
          break;
        }

        case GTMTypes.CLIPART: {
          gtmData = {
            Clipart: data,
          };
          break;
        }
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
    if (gtmData) {
      pushToGTM(gtmData);
    }
  }
}

export default GTM;
