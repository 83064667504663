import gql from 'graphql-tag';

import { DataProxy } from 'apollo-cache';

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUser on User {
    id
    email
    name
    organization
    phone
    role
    isInternal
    updatedAt
    signedUpAt
    createdAt
    ab {
      name
      value
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  {
    currentUser {
      ...CurrentUser
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

export const SIGN_OUT_MUTATION = gql`
  mutation SignOut {
    signOut {
      ...CurrentUser
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

export interface CurrentUserData {
  id: string | any;
  email: string | any;
  name: string | any;
  organization: string | any;
  role: string | any;
}

export function updateCurrentUser(cache: DataProxy, data: CurrentUserData, query: any = CURRENT_USER_QUERY) {
  cache.writeQuery({
    query,
    data: { currentUser: data },
  });
}

export function signOutUser(cache: DataProxy, data: CurrentUserData, query: any = CURRENT_USER_QUERY) {
  const { currentUser }: any = cache.readQuery({ query });
  const newData = Object.assign({}, { ...currentUser }, { ...data });
  cache.writeQuery({
    query,
    data: { currentUser: newData },
  });
}

export function getCurrentUser(cache: DataProxy) {
  const { currentUser }: any = cache.readQuery({ query: CURRENT_USER_QUERY });
  return currentUser;
}
