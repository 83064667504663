import _ from 'lodash';

import { StateContainer } from 'infra-frontend/helpers/apollo';
import * as t from 'inkp-design-sdk/types.g';
import { OrderQuoteItem, Order, Cart, CartItemProduct, OrderQuoteItemProduct } from 'inkp-order-sdk/types.g';
import { parseProductItemId } from 'inkp-product-sdk';
import { PrintZone as PrintZonesObject, DesignTemplate, Product, ProductColor } from 'inkp-product-sdk/types.g';
import { rotatedPosition, reversedRotatedPositions } from 'inkp-design-sdk/geometry';

import { Shape, ShapeType, isTextShape, isImageShape, isClipArtShape } from '../../interfaces/Canvas';
import { createPrintArea, templateCanvasData, pixelPrintZone } from '../../pages/DesignTool/utils';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_CATEGORY,
  DEFAULT_TEXT_COLOR_HEX,
  getNumLines,
  computeFontSize,
} from '../../util/canvas';

const SHAPE_IMAGE_PREFIX = 'image';
const SHAPE_CLIPART_PREFIX = 'clipart';
const SHAPE_TEXT_PREFIX = 'text';

type PrintZone = any;

export interface DesignCartProductInputSide {
  name: string;
  printZonesObjects: PrintZonesObject[];
}

export interface DesignCartProductInput extends Omit<Product, 'sides'> {
  id: string;
  designTemplates: DesignTemplate[];
  sides: DesignCartProductInputSide[];
}

export interface DesignCartProduct {
  productId: string;
  colors: string[];
  designIndex: number;
  designId: string | null;
}

export interface DesignCartDesignSide {
  name: string;
  shapes: Shape[];
  printZoneId: string;
  inchPerPx: number;
}

export interface DesignCartDesign {
  sides: DesignCartDesignSide[];
  userNote: string | null;
}

export interface DesignCartState {
  saved: boolean;
  redesigning: boolean;
  editingDesign: boolean;
  lastTextColor: string;
  products: DesignCartProduct[];
  designs: DesignCartDesign[];
  sizesQuantities: {
    name: string;
    qty: number;
  }[];
  latestDesignId: string | null;
  latestProductId: string | null;
}

function genId(prefix: string) {
  return `${prefix}-${Date.now()}${Math.round(Math.round(Math.random() * 1000))}`;
}

function newShape(shape: Partial<Shape>): Shape {
  //@ts-ignore
  return Object.assign(
    {
      artworkId: null,
      width: 0,
      height: 0,
      rotation: 0,
      zIndex: 0,
      src: null,
      svg: null,
      naturalWidth: null,
      naturalHeight: null,
      flip: false,
      flop: false,
      color: null,
      text: null,
      fontFamily: null,
      fontCategory: null,
      fontSize: null,
      align: null,
    },
    shape
  );
}

function roundValues(shape: Shape) {
  const rounded = Object.assign({}, shape);
  rounded.x = Math.round(rounded.x);
  rounded.y = Math.round(rounded.y);
  if (rounded.width) {
    rounded.width = Math.round(rounded.width);
  }
  if (rounded.height) {
    rounded.height = Math.round(rounded.height);
  }
  rounded.rotation = Math.round(rounded.rotation);
  rounded.zIndex = Math.round(rounded.zIndex);

  if (isImageShape(rounded)) {
    rounded.naturalWidth = Math.round(rounded.naturalWidth);
    rounded.naturalHeight = Math.round(rounded.naturalHeight);
  }
  return rounded;
}

function createBaseDesignCartProduct(productId: string): DesignCartProduct {
  return {
    productId,
    colors: [],
    designIndex: 0, // Allow only one design for now
    designId: null,
  };
}

function duplicateShape(shape: Shape): Shape {
  const { type } = shape;
  const prefix: string = (type as string).toLowerCase();

  return {
    ...shape,
    id: genId(prefix),
  };
}

const DESIGN_FRAGMENT = `
  userNote
  sides {
    name
    shapes {
      type
      id
      display
      x
      y
      width
      height
      rotation
      zIndex

      artworkId
      src
      svg
      naturalWidth
      naturalHeight
      flip
      flop

      color

      text
      fontFamily
      fontCategory
      fontSize
      align
    }
    printZoneId
    inchPerPx
  }
`;

export const DESIGN_CART_SHAPE = `
  {
    saved
    redesigning
    editingDesign
    lastTextColor
    products {
      productId
      colors
      designIndex
      designId
    }

    designs {
      ${DESIGN_FRAGMENT}
    }

    sizesQuantities {
      name
      qty
    }

    latestDesignId
    latestProductId
  }
`;

const createDefaultDesignSide = (productSide: DesignCartProductInputSide): DesignCartDesignSide => {
  return {
    name: productSide.name,
    shapes: [],
    printZoneId: productSide.printZonesObjects[0].id,
    inchPerPx: 0,
  };
};

export const recalculateDesignSidesForProduct = (
  designs: DesignCartDesign[],
  product: DesignCartProductInput,
  previousProduct: DesignCartProductInput
): DesignCartDesign[] => {
  const newDesigns = designs.map((design) => {
    let sides = product.sides.map((productSide) => {
      let designSide =
        design.sides.find(({ name }) => name === productSide.name) || createDefaultDesignSide(productSide);
      const template = product.designTemplates.find(({ side }) => side === designSide.name);
      if (!template) {
        return designSide;
      }
      const previousProductSide = previousProduct.sides.find(({ name }) => name === productSide.name);
      if (!previousProductSide) {
        return designSide;
      }

      const canvasData = templateCanvasData(template);
      const printArea = createPrintArea(template, canvasData);
      const printZones = productSide.printZonesObjects.map((printZone) =>
        pixelPrintZone(printZone, canvasData.inchPerPx, printArea)
      );
      const mainPrintZone = printZones[0]; // TODO: Figure out what is main printzone

      // Calculate shape's new width and height depending on previous print area and new print area
      designSide.shapes = designSide.shapes.map((shape) => {
        const rPosition = rotatedPosition(shape);
        const printAreaRatio = {
          width: rPosition.width / (previousProductSide.printZonesObjects[0].size.width / designSide.inchPerPx),
          height: rPosition.height / (previousProductSide.printZonesObjects[0].size.height / designSide.inchPerPx),
        };
        const shapeAspect = rPosition.width / rPosition.height;
        const printZoneAspect = mainPrintZone.width / mainPrintZone.height;
        let _width;
        let _height;
        if (printZoneAspect > shapeAspect) {
          _height = mainPrintZone.height * printAreaRatio.height;
          _width = _height * shapeAspect;
        } else {
          _width = mainPrintZone.width * printAreaRatio.width;
          _height = _width / shapeAspect;
        }

        // Use x, y ratio of shape to approximate shape's new x, y position
        const middle = { x: rPosition.x + rPosition.width / 2, y: rPosition.y + rPosition.height / 2 };
        const yRatio = (middle.y * printAreaRatio.height) / rPosition.height;
        const xRatio = (middle.x * printAreaRatio.width) / rPosition.width;
        const _yCenter = mainPrintZone.height * yRatio;
        const _xCenter = mainPrintZone.width * xRatio;
        let { x, y, width, height } = reversedRotatedPositions(shape, {
          x: _xCenter - _width / 2,
          y: _yCenter - _height / 2,
          width: _width,
          height: _height,
        });

        let newShape = Object.assign({}, shape, {
          width,
          height,
          x,
          y,
        });
        if (isTextShape(newShape)) {
          let fontSize = computeFontSize(newShape);
          newShape.fontSize = fontSize;
        }
        return roundValues(newShape);
      });
      designSide.inchPerPx = canvasData.inchPerPx;
      designSide.printZoneId = productSide.printZonesObjects[0].id; // Design Tool currently only supports one printZone per side
      return designSide;
    });
    // TODO: Because of an apollo bug, we cannot put the empty side in the beginning of the array,
    // need to further investiage, for now, we are sorting so that non empty side is the head of the array
    sides = _.sortBy(sides, (side) => 9999 - side.shapes.length);
    return Object.assign({}, design, {
      sides,
    });
  });
  return newDesigns;
};

export default class DesignCart extends StateContainer<DesignCartState> {
  initialState = {
    designs: [],
    editingDesign: false,
    lastTextColor: DEFAULT_TEXT_COLOR_HEX,
    latestDesignId: null,
    latestProductId: null,
    products: [],
    redesigning: false,
    saved: false,
    showMockups: false,
    sizesQuantities: [],
  };

  shape = DESIGN_CART_SHAPE;

  addProduct = (
    product: DesignCartProductInput,
    color: string,
    productInputs: DesignCartProductInput[],
    replaceColor?: boolean,
    _previousProduct?: DesignCartProductInput
  ) => {
    let products = this.state.products.slice();
    let designs = this.state.designs.slice();
    let productIndex = this.state.products.findIndex(({ productId }: { productId: string }) => productId == product.id);
    if (productIndex < 0) {
      if (designs.length === 0) {
        designs = [{ sides: product.sides.map(createDefaultDesignSide), userNote: null }]; // Allow only one design for MVP
      }
      const previousProduct =
        products.length > 0 && productInputs.find((productInput) => productInput.id === products[0].productId);
      if (previousProduct) {
        designs = recalculateDesignSidesForProduct(designs, product, previousProduct);
      }
      products = [
        {
          productId: product.id,
          colors: [],
          designIndex: 0, // Allow only one design for now
          designId: null,
        },
      ]; // Allow only one product for MVP
      productIndex = products.length - 1;
    }

    if (_previousProduct) {
      designs = recalculateDesignSidesForProduct(designs, product, _previousProduct);
    }

    products = products.map((product, index) => {
      if (index !== productIndex) return product;
      return Object.assign({}, product, {
        colors: replaceColor ? [color] : _.uniq(product.colors.concat(color)),
      });
    });

    this.setState({ products, designs, saved: false });
  };

  changeProductColor = (productId: string, color: string) => {
    const products = this.state.products.map((product) => {
      if (product.productId !== productId) return product;
      return Object.assign({}, product, {
        colors: [color],
      });
    });
    this.setState({ products, saved: false });
  };

  addProductColor = (productId: string, color: string) => {
    const products = this.state.products.map((product) => {
      if (product.productId !== productId) return product;
      return Object.assign({}, product, {
        colors: product.colors.concat(color),
      });
    });

    this.setState({ products, saved: false });
  };

  removeProductColor = (productId: string, color: string) => {
    let products = this.state.products
      .map((product) => {
        if (product.productId !== productId) return product;
        return Object.assign({}, product, {
          colors: _.reject(product.colors, (value) => color === value),
        });
      })
      .filter((product) => product.colors.length > 0);

    const designIndexes = _.uniq(products.map(({ designIndex }) => designIndex));
    const deletedIndexes: number[] = [];
    const designs = _.compact(
      this.state.designs.map((design, index) => {
        if (designIndexes.indexOf(index) > -1) {
          return design;
        }
        deletedIndexes.push(index);
        return null;
      })
    );

    products = products.map((product) =>
      Object.assign({}, product, {
        designIndex: product.designIndex - deletedIndexes.filter((index) => product.designIndex > index).length,
      })
    );

    this.setState({ products, designs, saved: false });
  };

  removeProduct = (productId: string) => {
    let products = _.reject(this.state.products, (product) => product.productId === productId);
    const designIndexes = _.uniq(products.map(({ designIndex }) => designIndex));
    const deletedIndexes: number[] = [];
    const designs = _.compact(
      this.state.designs.map((design, index) => {
        if (designIndexes.indexOf(index) > -1) {
          return design;
        }
        deletedIndexes.push(index);
        return null;
      })
    );

    products = products.map((product) =>
      Object.assign({}, product, {
        designIndex: product.designIndex - deletedIndexes.filter((index) => product.designIndex > index).length,
      })
    );

    this.setState({ products, designs, saved: false });
  };

  currentZIndex = (designIndex: number, side: string) => {
    const design = this.state.designs[designIndex];
    if (!design) return 0;
    const designSide = design.sides.find(({ name }: { name: string }) => name === side);
    if (!designSide) return 0;
    return designSide.shapes.length;
  };

  lastShape = (designIndex: number, side: string) => {
    const design = this.state.designs[designIndex];
    if (!design) return null;
    const designSide = design.sides.find(({ name }: { name: string }) => name === side);
    if (!designSide) return null;
    return designSide.shapes[designSide.shapes.length - 1];
  };

  addShape = (designIndex: number, side: string, shape: Shape, inchPerPx: number) => {
    let designs = this.state.designs.map((design: any, index: number) => {
      if (designIndex !== index) return design;
      let sides = design.sides.map((designSide: any) => {
        if (designSide.name !== side) return designSide;
        return Object.assign({}, designSide, {
          shapes: designSide.shapes.concat([roundValues(shape)]),
          inchPerPx,
        });
      });
      // TODO: Because of an apollo bug, we cannot put the empty side in the beginning of the array,
      // need to further investiage, for now, we are sorting so that non empty side is the head of the array
      sides = _.sortBy(sides, (side) => 9999 - side.shapes.length);
      return Object.assign({}, design, {
        sides,
      });
    });

    return this.setState({ designs, saved: false });
  };

  updateShape = (designIndex: number, side: string, updatedShape: Shape) => {
    let lastTextColor = this.state.lastTextColor;
    const designs = this.state.designs.map((design: any, index: number) => {
      if (designIndex !== index) return design;
      return Object.assign({}, design, {
        sides: design.sides.map((designSide: any) => {
          if (designSide.name !== side) return designSide;
          return Object.assign({}, designSide, {
            shapes: designSide.shapes.map((shape: Shape) => {
              if (shape.id !== updatedShape.id) return shape;
              if (isTextShape(updatedShape) || isClipArtShape(updatedShape)) {
                lastTextColor = updatedShape.color;
              }
              return roundValues(updatedShape);
            }),
          });
        }),
      });
    });

    return this.setState({ designs, saved: false, lastTextColor });
  };

  rearrange = (designIndex: number, side: string, shapeId: string, newZIndex: number) => {
    const designs = this.state.designs.map((design: any, index: number) => {
      if (designIndex !== index) return design;
      return Object.assign({}, design, {
        sides: design.sides.map((designSide: any) => {
          if (designSide.name !== side) return designSide;

          let shapes = designSide.shapes.map((shape: Shape) => {
            if (shape.id !== shapeId) return shape;
            return Object.assign({}, shape, { zIndex: newZIndex + (newZIndex > shape.zIndex ? 1 : -1) * 0.5 });
          }); // Make the shape sit in between two layers

          shapes = shapes.sort((shape1: Shape, shape2: Shape) => shape1.zIndex - shape2.zIndex); // Sort the shapes by zIndex

          return Object.assign({}, designSide, {
            shapes: shapes.map((shape: Shape, index: number) => {
              return Object.assign({}, shape, { zIndex: index }); // Reassign all zIndex by their position in their array
            }),
          });
        }),
      });
    });

    return this.setState({ designs });
  };

  deleteShape = (designIndex: number, side: string, shape: Shape) => {
    const designs = this.state.designs.map((design: any, index: number) => {
      if (designIndex !== index) return design;
      let sides = design.sides.map((designSide: any) => {
        if (designSide.name !== side) return designSide;

        let shapes = designSide.shapes.filter((_shape: Shape) => {
          return _shape.id !== shape.id;
        });

        return Object.assign({}, designSide, {
          shapes: shapes.map((_shape: Shape, index: number) => {
            return Object.assign({}, _shape, { zIndex: index }); // Reassign all zIndex by their position in their array
          }),
        });
      });

      // TODO: For some apollo bug, we cannot put the empty side in the beginning of the array,
      // need to further investiage, for now, we are sorting so that non empty side is the head of the array
      sides = _.sortBy(sides, (side) => 9999 - side.shapes.length);
      return Object.assign({}, design, {
        sides,
      });
    });

    return this.setState({ designs });
  };

  addImage = (
    designIndex: number,
    side: string,
    image: {
      artworkId: string;
      src: string;
      display: string;
      dimensions: { width: number; height: number };
    },
    printZone: PrintZone,
    inchPerPx: number
  ) => {
    const id = genId(SHAPE_IMAGE_PREFIX);
    let width: number, height: number;
    const printZoneRatio = printZone.width / printZone.height;
    const imageRatio = image.dimensions.width / image.dimensions.height;

    if (printZoneRatio > imageRatio) {
      height = printZone.height;
      width = height * imageRatio;
    } else {
      width = printZone.width;
      height = width / imageRatio;
    }

    const shape = newShape({
      id,
      artworkId: image.artworkId,
      type: ShapeType.IMAGE,
      display: image.display,
      x: 0,
      y: 0,
      width,
      height,
      zIndex: this.currentZIndex(designIndex, side),
      src: image.src,
      naturalWidth: image.dimensions.width,
      naturalHeight: image.dimensions.height,
    });

    return this.addShape(designIndex, side, shape, inchPerPx);
  };

  addText = (
    designIndex: number,
    side: string,
    text: string,
    color: string = DEFAULT_TEXT_COLOR_HEX,
    printZone: PrintZone,
    inchPerPx: number
  ) => {
    const id = genId(SHAPE_TEXT_PREFIX);
    const fontSize = 32;
    const lineHeight = 1.25;
    const shape = newShape({
      id,
      type: ShapeType.TEXT,
      display: text,
      x: 0,
      y: 0,
      width: 0,
      height: getNumLines(text) * fontSize * lineHeight,
      zIndex: this.currentZIndex(designIndex, side),
      text: text,
      color: color,
      fontSize: fontSize,
      align: 'center',
      fontFamily: DEFAULT_FONT_FAMILY,
      fontCategory: DEFAULT_FONT_CATEGORY.name,
    });

    this.addShape(designIndex, side, shape, inchPerPx);
    this.setState({ lastTextColor: color });
    return id;
  };

  addClipArt = (
    designIndex: number,
    side: string,
    clipart: {
      svg: string;
      display: string;
      dimensions: { width: number; height: number };
    },
    color: string = DEFAULT_TEXT_COLOR_HEX,
    printZone: PrintZone,
    inchPerPx: number
  ) => {
    const id = genId(SHAPE_CLIPART_PREFIX);
    let width: number, height: number;
    const printZoneRatio = printZone.width / printZone.height;
    const imageRatio = clipart.dimensions.width / clipart.dimensions.height;

    if (printZoneRatio > imageRatio) {
      height = printZone.height;
      width = height * imageRatio;
    } else {
      width = printZone.width;
      height = width / imageRatio;
    }

    const shape = newShape({
      id,
      type: ShapeType.CLIPART,
      display: clipart.display,
      x: 0,
      y: 0,
      width,
      height,
      color: color,
      zIndex: this.currentZIndex(designIndex, side),
      svg: clipart.svg,
    });

    return this.addShape(designIndex, side, shape, inchPerPx);
  };

  updateQty = (size: string, qty: number) => {
    const sizesQuantities = _.cloneDeep(this.state.sizesQuantities);

    let sizeObj = _.find(sizesQuantities, { name: size });
    if (!sizeObj) {
      sizeObj = { name: size, qty };
      sizesQuantities.push(sizeObj);
    } else {
      sizeObj.qty = qty;
    }
    return this.setState({ sizesQuantities });
  };

  updateNote = (note: string, designIndex: number) => {
    let designs = this.state.designs;
    let design = _.cloneDeep(designs[designIndex]);
    if (!design) return;
    design.userNote = note;
    designs[designIndex] = design;
    this.setState({ designs, saved: false });
  };

  assignDesignIds = (shareable: t.Shareable) => {
    const products = this.state.products.map((product) =>
      Object.assign({}, product, {
        designId: shareable.products.find(({ productId }) => productId === product.productId)!.designId,
      })
    );

    return this.setState({ products, saved: true });
  };

  assignEditedDesignIds = (designId: string) => {
    const products = this.state.products.map((product) =>
      Object.assign({}, product, {
        designId: designId,
      })
    );

    return this.setState({ products, saved: true });
  };

  private _restoreSizesQuantities = (
    items: { product: CartItemProduct | OrderQuoteItemProduct }[],
    itemIndexes: number[]
  ) => {
    return _.compact(
      _.map(itemIndexes, (index) => {
        const item = items[index];
        if (!item) return null;
        const { size } = parseProductItemId(item.product.productItemId);
        const qty = item.product.quantity;
        return { name: size, qty };
      })
    );
  };

  restoreFromShareable = (shareable: t.Shareable) => {
    const products = [];
    const designs = [];
    const designIdToIndex: { [key: string]: number } = {};
    for (const product of shareable.products) {
      if (!designIdToIndex[product!.design!.id!] && designIdToIndex[product!.design!.id!]! !== 0) {
        designs.push(product!.design!);
        designIdToIndex[product!.design!.id!] = designs.length - 1;
      }
      products.push({
        productId: product!.productId!,
        colors: product!.colors!.map(({ name }: { name: string }) => name),
        designIndex: designIdToIndex[product!.design!.id!],
        designId: product!.design!.id!,
      });
    }

    return this.setState({ designs, products, editingDesign: true, saved: true });
  };

  restoreFromOrder = (order: Order, itemIndexes: number[]) => {
    const products: any = [];
    const designs: any = [];
    const designIdToIndex: { [key: string]: number } = {};
    const item = order.quote.items[itemIndexes[0]]; // TODO: Change when we support multiple designs in DesignTool
    let itemsByProductId: { [key: string]: OrderQuoteItem[] } = {};
    if (!item) return;

    const design = item.design;
    if (!designIdToIndex[design!.id]) {
      designs.push(design);
      designIdToIndex[design!.id] = designs.length - 1;
    }

    for (const itemIndex of itemIndexes) {
      const quoteItem = order.quote.items[itemIndex];
      const productId = _.get(quoteItem, 'product.productItem.productId');
      if (!quoteItem || !productId) continue;
      itemsByProductId[productId] = (itemsByProductId[productId] || []).concat([quoteItem]);
    }

    for (const productId in itemsByProductId) {
      const quoteItems = itemsByProductId[productId];
      const colors = _.compact(quoteItems.map((quoteItem) => _.get(quoteItem, 'product.productItem.color')));
      products.push({
        productId,
        colors,
        designIndex: designIdToIndex[design!.id!],
        designId: null,
      });
    }

    const sizesQuantities = this._restoreSizesQuantities(order.quote.items, itemIndexes);
    const redesigning = true;

    return this.setState({ designs, products, sizesQuantities, redesigning, saved: false });
  };

  restoreFromCart = (cart: Cart, itemIndexes: number[]) => {
    const products: DesignCartProduct[] = [];
    const designs: DesignCartDesign[] = [];
    const designIdToIndex: { [key: string]: number } = {};
    const item = cart.items[itemIndexes[0]];
    if (!item) return;
    const design = _.cloneDeep(item.design!) as t.Design;

    for (const productSide of (item.product!.product! as Product).sides) {
      if (!_.find(design.sides, { name: productSide.name })) {
        design.sides.push({
          name: productSide.name as any,
          shapes: [],
          printZoneId: productSide.printZonesObjects![0].id,
          inchPerPx: 0,
        });
      }
    }

    if (!designIdToIndex[design!.id]) {
      designs.push(design);
      designIdToIndex[design!.id] = designs.length - 1;
    }

    products.push({
      productId: (item.product!.productItem! as any).productId,
      colors: [(item.product!.productItem! as any).color],
      designIndex: designIdToIndex[design!.id!],
      designId: null,
    });

    const sizesQuantities = this._restoreSizesQuantities(cart.items, itemIndexes);

    return this.setState({ designs, products, sizesQuantities, saved: false, editingDesign: true });
  };

  restoreFromCartProduct = ({
    product,
    design,
    colorName,
    editingDesign = false,
  }: {
    product: Product;
    design: t.Design;
    colorName: string;
    editingDesign?: boolean;
  }) => {
    const products: DesignCartProduct[] = [];
    let designs: DesignCartDesign[] = [];
    const designIdToIndex: { [key: string]: number } = {};
    for (const productSide of product.sides) {
      if (!_.find(design.sides, { name: productSide.name })) {
        design.sides.push({
          name: productSide.name as any,
          shapes: [],
          printZoneId: productSide.printZonesObjects![0].id,
          inchPerPx: 0,
        });
      }
    }

    if (!designIdToIndex[design!.id]) {
      designs.push(design);
      designIdToIndex[design!.id] = designs.length - 1;
    }

    designs = designs.map((design) => {
      const { sides } = design;
      const duplicatedSides = sides.map((side) => {
        const { shapes } = side;
        const duplicatedShapes = shapes.map((shape) => duplicateShape(shape));
        return {
          ...side,
          shapes: duplicatedShapes,
        };
      });
      return {
        ...design,
        userNote: null,
        sides: duplicatedSides,
      };
    });

    products.push({
      productId: product.id,
      colors: [colorName],
      designIndex: designIdToIndex[design.id],
      designId: null,
    });

    let productColor: ProductColor | undefined = product.colors.find(
      (productColor: ProductColor) => productColor.name.toLowerCase() === colorName.toLowerCase()
    );
    if (!productColor) {
      productColor = product.colors[0];
    }

    const sizeForColor: string = productColor.sizes[0];
    const sizesQuantities = [{ name: sizeForColor, qty: 0 }];

    return this.setState({
      designs,
      products,
      sizesQuantities,
      saved: false,
      editingDesign,
    });
  };

  cleanUp = () => {
    return this.setState(this.initialState);
  };

  setContinueDesignState = (_products: DesignCartProduct[], _designs: DesignCartDesign[]) => {
    const products = _products.map((product) => {
      const { productId, colors } = product;
      const baseDesignCartProduct: DesignCartProduct = createBaseDesignCartProduct(productId);
      return {
        ...baseDesignCartProduct,
        colors: colors.slice(),
      };
    });

    const designs = _designs.map((design) => {
      const { sides } = design;
      const duplicatedSides = sides.map((side) => {
        const { shapes } = side;
        const duplicatedShapes = shapes.map((shape) => duplicateShape(shape));
        return {
          ...side,
          shapes: duplicatedShapes,
        };
      });
      return {
        ...design,
        userNote: null,
        sides: duplicatedSides,
      };
    });
    const sizesQuantities: any = [];

    return this.setState({ products, designs, sizesQuantities });
  };

  resetDesign = () => {
    const products = this.state.products.map((product) => {
      return Object.assign({}, product, {
        designId: null,
      });
    });
    const designs = this.state.designs.map((design) =>
      Object.assign({}, design, {
        userNote: null,
        sides: design.sides.map((designSide) =>
          Object.assign({}, designSide, {
            shapes: [],
          })
        ),
      })
    );
    const sizesQuantities: any = [];

    return this.setState({ products, designs, sizesQuantities });
  };

  resetSizesQuantities = () => {
    return this.setState({
      sizesQuantities: [],
    });
  };

  setEditingDesign = (value: boolean) => {
    return this.setState({
      editingDesign: value,
    });
  };

  setLatestDesignId = (designId: string) => {
    return this.setState({
      latestDesignId: designId,
    });
  };

  setLatestProductId = (productId: string) => {
    return this.setState({
      latestProductId: productId,
    });
  };

  setSourceProductDesignInfo = (productId: string, designId: string) => {
    return this.setState({
      latestProductId: productId,
      latestDesignId: designId,
    });
  };
}
