import "@babel/polyfill";

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';

import env from 'infra-util/env';
import App from './app';
import apolloOptions from './apollo';
// Setup apollo client with our config and state management logic
import { setupClient } from 'infra-frontend/helpers/apollo';
import GTM from './util/gtm';
import config from './config';

import 'inkp-pigment/build/pigment.css';
import '@mdi/font/css/materialdesignicons.css';

import * as Sentry from '@sentry/browser';

if (env === 'production') {
  Sentry.init({ dsn: config.sentry.dsn });
  if (typeof window === 'object') {
    // @ts-ignore browser scope no types
    window.Sentry = Sentry;
  }
}

// To make sure all needed asynchronous components are ready
loadableReady(() => {
  // Use .hydrate when server-side rendering is enabled, .render for client-side rendering only
  const client = setupClient(apolloOptions).client;
  GTM.apolloClient = client;

  ReactDOM.hydrate(
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>,
    document.getElementById('root')
  );
});
