import { Query, Mutation } from 'react-apollo';
import { DataProxy } from 'apollo-cache';
import gql from 'graphql-tag';
import {
  Order,
  Mutation as MuationResults,
  MutationplaceOrderArgs,
  MutationupdateToCurrentUserIdArgs,
} from 'inkp-order-sdk/types.g';

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    orderedAt
    dueAt
    status
    shippingAddress {
      name
      company
      address1
      address2
      city
      state
      zip
      country
      phone
    }
    billingAddress {
      name
      company
      address1
      address2
      city
      state
      zip
      country
      phone
    }
    quote {
      items {
        status
        product {
          productItemId
          quantity
          product {
            name
            id
            colors {
              class
              heather
              hex
              name
              startingPrice
              productId
              sizesObjects {
                id
                name
                inStock
                price
              }
            }
            comments
            createdAt
            deletedAt
            description
            designTemplates {
              side
              id
              data {
                version
                dimensions {
                  height
                  width
                }
                size {
                  height
                  width
                }
              }
            }
            image {
              label
              url
            }
            material
            name
            type
            styleId
          }
          productItem {
            id
            color
            createdAt
            deletedAt
            ooProductId
            quantity
            size
            sizing
            price
          }
        }
        designId
        printType
        tracking {
          number
          method
          carrier
          link
        }
        amounts {
          blanks
          printing
          shipping
          upcharge
          subtotal
          discount
          adjustment
          tax
          total
        }
        design {
          id
          parentId
          userId
          sides {
            name
          }
          shareable {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
      shipping {
        speed
      }
      amounts {
        blanks
        printing
        shipping
        upcharge
        subtotal
        discount
        adjustment
        tax
        total
      }
      invoiceId
      payment {
        method
        customer
        cardId
        last4
        processorType
      }
      transactions {
        debitId
        amounts {
          shipping
          upcharge
          subtotal
          discount
          adjustment
          tax
          total
        }
        orderEventId
        type
        items {
          amounts {
            shipping
            upcharge
            subtotal
            discount
            adjustment
            tax
            total
          }
          itemIndex
        }
      }
    }
    features {
      requiresCustomerApproval
      customerInstructions
    }
    coupons {
      code
      items {
        itemIndex
        discount
      }
    }
    billing {
      transactions {
        creditId
        amounts {
          shipping
          upcharge
          subtotal
          discount
          adjustment
          tax
          total
        }
        method
        orderEventId
        type
        items {
          amounts {
            shipping
            upcharge
            subtotal
            discount
            adjustment
            tax
            total
          }
          itemIndex
        }
      }
    }
  }
`;

export const ORDER_CURSOR_OUTPUT_FRAGMENT = gql`
  fragment Orders on OrderCursorOutput {
    orders {
      id
      orderedAt
      dueAt
      status
      quote {
        items {
          status
          product {
            quantity
            product {
              name
              id
              colors {
                class
                heather
                hex
                name
                startingPrice
                productId
              }
              designTemplates {
                side
                id
              }
              image {
                label
                url
              }
            }
            productItem {
              id
              color
              quantity
              size
            }
          }
          designId
          tracking {
            number
            method
            carrier
            link
          }
          amounts {
            subtotal
          }
          design {
            id
            sides {
              name
            }
            shareable {
              id
              name
            }
          }
        }
        amounts {
          total
        }
      }
      features {
        requiresCustomerApproval
        customerInstructions
      }
    }
  }
`;

export const GET_MY_ORDERS_QUERY = gql`
  query GetMyOrders($input: OrderCursorInput!) {
    ordersByUser(input: $input) {
      ...Orders
    }
  }

  ${ORDER_CURSOR_OUTPUT_FRAGMENT}
`;

export const GET_MY_ORDER_BY_ID_QUERY = gql`
  query GetMyOrderById($id: String!) {
    orderByIdAndUser(id: $id) {
      ...OrderFragment
    }
  }

  ${ORDER_FRAGMENT}
`;

export class MutationPlaceOrder extends Mutation<MuationResults, MutationplaceOrderArgs> {}
export const MUTATION_PLACE_ORDER = gql`
  mutation PlaceOrder($cartId: String!, $amounts: [OrderAmountsInput!]) {
    placeOrder(cartId: $cartId, amounts: $amounts) {
      ...OrderFragment
    }
  }

  ${ORDER_FRAGMENT}
`;

export const GET_ORDER_BY_ID_AND_EMAIL_QUERY = gql`
  query GetOrderByIdAndEmail($id: String!, $email: String!) {
    orderByIdAndEmail(id: $id, email: $email) {
      ...OrderFragment
    }
  }

  ${ORDER_FRAGMENT}
`
export class MutationUpdateOrderUserId extends Mutation<MuationResults, MutationupdateToCurrentUserIdArgs> {}
export const MUTATION_UPDATE_ORDER_USER_ID = gql`
  mutation updateToCurrentUserId($orderId: String!) {
    updateToCurrentUserId(orderId: $orderId) {
      ...OrderFragment
    }
  }

  ${ORDER_FRAGMENT}
`;


export function updateOrderById(cache: DataProxy, order: Order, query: any = GET_MY_ORDER_BY_ID_QUERY) {
  cache.writeQuery({
    query: GET_MY_ORDER_BY_ID_QUERY,
    data: { orderByIdAndUser: order },
    variables: { id: order.id },
  });
}

export function getOrderById(cache: DataProxy, orderId: string) {
  const { orderByIdAndUser }: any = cache.readQuery({ query: GET_MY_ORDER_BY_ID_QUERY, variables: { id: orderId } });
  return orderByIdAndUser;
}
