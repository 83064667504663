import { rotatedPosition } from 'inkp-design-sdk/geometry';
import { DesignTemplate } from 'inkp-product-sdk/types.g';

import { Shape, PrintArea } from '../../interfaces/Canvas';

export function createPrintArea(template: any, canvasData: any): PrintArea {
  return {
    x: template.data.bounds.topLeft.x,
    y: template.data.bounds.topLeft.y,
    width: canvasData.printableWidthPx,
    height: canvasData.printableHeightPx,
  };
}

export function templateCanvasData(template: DesignTemplate) {
  const printableWidthInch = template.data!.dimensions.width;
  // This calculation reflects the calculations made in oo-mockup-tools when calculating a template's bounds and the width in inches
  const printableWidthPx = Math.max(
    Math.hypot(
      Math.abs(template.data!.bounds.topRight.x - template.data!.bounds.topLeft.x),
      Math.abs(template.data!.bounds.topRight.y - template.data!.bounds.topLeft.y)
    ),
    Math.hypot(
      Math.abs(template.data!.bounds.bottomRight.x - template.data!.bounds.bottomLeft.x),
      Math.abs(template.data!.bounds.bottomRight.y - template.data!.bounds.bottomLeft.y)
    )
  );
  const printableHeightPx = Math.max(
    Math.hypot(
      Math.abs(template.data!.bounds.topLeft.x - template.data!.bounds.bottomLeft.x),
      Math.abs(template.data!.bounds.topLeft.y - template.data!.bounds.bottomLeft.y)
    ),
    Math.hypot(
      Math.abs(template.data!.bounds.topRight.x - template.data!.bounds.bottomRight.x),
      Math.abs(template.data!.bounds.topRight.y - template.data!.bounds.bottomRight.y)
    )
  );

  const inchPerPx = printableWidthInch / printableWidthPx;
  return { inchPerPx, printableWidthPx, printableHeightPx };
}

export function pixelPrintZone(printZone: any, inchPerPx: number, printArea: any) {
  const width = Math.round(printZone.size.width / inchPerPx);
  const height = Math.round(printZone.size.height / inchPerPx);
  let x, y;
  if (printZone.position.left || printZone.position.left === 0) {
    x = printArea.x + printZone.position.left / inchPerPx;
  } else {
    x = printArea.x + printArea.width - (printZone.position.right || 0) / inchPerPx - width;
  }

  if (printZone.position.top || printZone.position.top === 0) {
    y = printArea.y + printZone.position.top / inchPerPx;
  } else {
    y = printArea.y + printArea.height - (printZone.position.bottom || 0) / inchPerPx - height;
  }

  x = Math.round(x);
  y = Math.round(y);

  return { width, height, x, y, name: printZone.name };
}

export function toFixed2(num: number) {
  return num.toFixed(2).replace(/\.?0+$/, '');
}

export function filterOutOfBoundShapes(shapes: Shape[], mainPrintZone: any) {
  return shapes.filter((shape) => {
    const rPosition = rotatedPosition(shape);
    const { x, y, width = 0, height = 0 } = rPosition;
    if (Math.ceil(x) < 0 || Math.ceil(y) < 0) return true;
    if (Math.floor(x + width) > mainPrintZone.width || Math.floor(y + height) > mainPrintZone.height) return true;
    return false;
  });
}
