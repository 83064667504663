import { GetDeliverByDate } from './Product';
import { SHIPPING_SPEED_ENUM } from 'inkp-order-sdk/types.g';
import { SHIPPING_SPEED_ENUM_MAPPING } from 'inkp-order-sdk/order';

export const getFreeShipDate = () => {
  const freeShipDate = GetDeliverByDate(
    new Date().toString(),
    SHIPPING_SPEED_ENUM.NINE_DAY
  );
  return freeShipDate;
};

export const getFastShipDate = () => {
  const fastShipDate = GetDeliverByDate(
    new Date().toString(),
    SHIPPING_SPEED_ENUM.FOUR_DAY
  );
  return fastShipDate;
};

export const getFastestShipDate = () => {
  const fastestShipDate = GetDeliverByDate(
    new Date().toString(),
    SHIPPING_SPEED_ENUM.THREE_DAY
  );
  return fastestShipDate;
};
