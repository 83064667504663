import configurator from 'inkp-config/configurator';

const configs = {
  base: {
    sentry: {
      dsn: 'https://a368321d253a4fe79d13b99e1ffbda90@sentry.io/1526825',
    },
    defaultDesignToolProduct: {
      id: 'gildan-cotton-t-shirt',
      color: 'White',
    },
    blog: "https://blog.inkpop.com",
  },
  test: {},
  stage: {},
  production: {},
};

export default configurator(configs);
