import { DataProxy } from 'apollo-cache';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Query as DesignsQueryResults,
  Mutation as DesignsMutationResults,
  QuerydesignByIdArgs,
  Design,
} from 'inkp-design-sdk/types.g';

export const DESIGN_SHAPE_FRAGMENT = gql`
  fragment DesignShapeFragment on Shape {
    id
    type
    display
    x
    y
    width
    height
    rotation
    zIndex
    src
    svg
    artworkId
    naturalWidth
    naturalHeight
    flip
    flop
    align
    color
    text
    fontFamily
    fontCategory
    fontSize
  }
`;

export const DESIGN_FRAGMENT = gql`
  fragment DesignFragment on Design {
    id
    shareableId
    shareable {
      id
      name
      userId
      email
      code
    }
    sides {
      name
      printZoneId
      printZone {
        name
        position {
          top
          bottom
          right
          left
        }
        size {
          width
          height
        }
      }
      inchPerPx
      shapes {
        ...DesignShapeFragment
      }
      artwork {
        fullColor
        colors {
          name
          hex
        }
      }
    }
    createdAt
    updatedAt
    userNote
  }

  ${DESIGN_SHAPE_FRAGMENT}
`;

export const QUERY_DESIGN_BY_ID = gql`
  query GetDesignById($id: String!) {
    designById(id: $id) {
      ...DesignFragment
    }
  }

  ${DESIGN_FRAGMENT}
`;

export class QueryDesignById extends Query<DesignsQueryResults, QuerydesignByIdArgs> {}

export function getDesignById(cache: DataProxy, designId: string): Design {
  const { designById }: any = cache.readQuery({ query: QUERY_DESIGN_BY_ID, variables: { id: designId } });
  return designById;
}
