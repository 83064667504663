export type Shape = TextShapeData | ImageShapeData | ClipArtShapeData;

export type GuidelinePosition = 'vertical-center' | 'horizontal-center';

export enum ShapeType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  CLIPART = 'CLIPART',
}

export const isTextShape = (shape: Shape): shape is TextShapeData => {
  return shape && shape.type === ShapeType.TEXT;
};

export const isImageShape = (shape: Shape): shape is ImageShapeData => {
  return shape && shape.type === ShapeType.TEXT;
};

export const isClipArtShape = (shape: Shape): shape is ClipArtShapeData => {
  return shape && shape.type === ShapeType.TEXT;
};

export interface TextShapeData {
  type: ShapeType.TEXT;

  /** Shape ID **/
  id: string;

  /** Shape Display Name **/
  display: string;

  /** Width of the text */
  width: number;

  /** Height of the text */
  height: number;

  /** X position of the text */
  x: number;

  /** Y position of the text */
  y: number;

  /** Rotation of the text */
  rotation: number;

  /** Font Family of the text */
  fontFamily: string;

  /** Font Category of the text */
  fontCategory: string;

  /** Font size of the text */
  fontSize: number;

  /** Alignment of the text */
  align: string;

  /** Color of the Stroke of the text */
  color: string;

  /** Update handler */
  text: string;

  /** zIndex */
  zIndex: number;
}

export interface ImageShapeData {
  type: ShapeType.IMAGE;

  /** Shape ID **/
  id: string;

  /** Image src **/
  src: string;

  /** Shape Display Name **/
  display: string;

  /** Width of the text */
  width: number;

  /** Height of the text */
  height: number;

  /** X position of the text */
  x: number;

  /** Y position of the text */
  y: number;

  /** Rotation of the text */
  rotation: number;

  // Vertical mirroring
  flip: boolean;

  // Horizontal mirroring
  flop: boolean;

  /** zIndex */
  zIndex: number;

  /** SP artworkId **/
  artworkId: string;

  /** Natrual dimension of the image */
  naturalWidth: number;
  naturalHeight: number;
}

export interface ImageShapeProps {
  shape: ImageShapeData;

  selected: boolean;

  mainPrintZone: {
    x: number;
    y: number;
    width: number;
    height: number;
  };

  /** Guideline Positions */
  guidelinePositions: GuidelinePosition[];

  /** Is resizeable */
  resizeable: boolean;

  /** Is draggable **/
  draggable: boolean;

  /** Flag to see if shape should snap to guidelines */
  snapToGuidelines: boolean;

  scale: {
    x: number;
    y: number;
  };

  /** Function executed when the image begins to be dragged */
  onDragStart: (shape: ImageShapeData) => void;

  /** Function executed when the image is being dragged */
  onDragMove: (shape: ImageShapeData, mousePosition: { x: number; y: number }) => void;

  /** Function executed when the image begins finishes to be dragged */
  onDragEnd: (shape: ImageShapeData) => void;

  /** Update handler */
  onUpdate: (shape: ImageShapeData) => void;

  /** Select handler */
  onSelect: (shape: ImageShapeData) => void;

  /** Mouse over handler */
  onMouseOver: (shape: ImageShapeData) => void;

  /** Mouse out handler */
  onMouseOut: (shape: ImageShapeData) => void;

  /** Resize start handler */
  onResizeStart: (shape: ImageShapeData) => void;

  /** Resize end handler */
  onResizeEnd: (shape: ImageShapeData) => void;

  /** Delete shape handler */
  onDeleteShape: (shape: ImageShapeData) => void;

  /** Image finished loading */
  onLoad?: (shape: ImageShapeData) => void;
}

export interface ClipArtShapeData {
  type: ShapeType.CLIPART;

  /** Shape ID **/
  id: string;

  /** SVG string */
  svg: string;

  /** Shape Display Name **/
  display: string;

  /** Width of the shape */
  width: number;

  /** Height of the shape */
  height: number;

  /** Color of the shape */
  color: string;

  /** X position of the shape */
  x: number;

  /** Y position of the shape */
  y: number;

  /** Rotation of the shape */
  rotation: number;

  /** Vertical mirroring */
  flip: boolean;

  /** Horizontal mirroring */
  flop: boolean;

  /** zIndex */
  zIndex: number;
}

export interface PrintArea {
  x: number;
  y: number;
  width: number;
  height: number;
}
