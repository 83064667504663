import React from 'react';
import gql from 'graphql-tag';

import { routes, path } from 'inkp-routes/public';
import { Product, ProductCategory } from 'inkp-product-sdk/types.g';
import ProductCard from 'inkp-components/dist/Components/ProductCard';

import classnames from 'classnames';

const RELATED_PRODUCT_CARDS_LIMIT_FOR_DESKTOP = 4;
const UNCATEGORIZED_LABEL: string = 'uncategorized';
const CATEGORY_SCOPE_TYPE: string = 'type';
const ITEMS_NUMBER_FOR_STARTING_PRICE = 50;

interface Props {
  relatedProducts: Product[];
  /** class to pass to product card */
  cardClass?: string;
  /** class to pass to container */
  containerClass?: string;
}

export const RELATED_PRODUCTS_QUERY = gql`
  fragment RelatedProductsFragment on Product {
    relatedProducts(limit: 4, methods: ["brand"]) {
      id
      name
      image {
        label
        url
      }
      categories {
        name
        scope
      }
      colors {
        name
        hex
        startingPrice
      }
    }
  }
`;

export default class RelatedProducts extends React.Component<Props> {
  render() {
    let { relatedProducts } = this.props;
    const { cardClass, containerClass } = this.props;
    relatedProducts = relatedProducts.slice(0, 6);

    return (
      <div className="flex flex-wrap -mx-1">
        {relatedProducts.map((relatedProduct: Product, index: number) => {
          const { categories, colors } = relatedProduct;
          const categoryType: ProductCategory | undefined =
            categories && categories.find((category: ProductCategory) => category.scope === CATEGORY_SCOPE_TYPE);
          const categoryTypeName: string = categoryType ? categoryType.name : UNCATEGORIZED_LABEL;
          const activeColor = colors[0];
          const price: number = activeColor.startingPrice;

          return (
            <div
              className={classnames('px-1', containerClass, {
                'md:d-n': index >= RELATED_PRODUCT_CARDS_LIMIT_FOR_DESKTOP,
              })}
              key={relatedProduct.id}
            >
              <ProductCard
                key={relatedProduct.id}
                id={relatedProduct.id}
                name={relatedProduct.name}
                image={relatedProduct.image.url}
                price={Math.round(price / ITEMS_NUMBER_FOR_STARTING_PRICE)}
                colorCount={relatedProduct.colors.length}
                pathname={path(routes.app.product.detail, { id: relatedProduct.id, category: categoryTypeName })}
                className={cardClass}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
