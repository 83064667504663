import * as React from 'react';
import { DataProxy } from 'apollo-cache';
import gql from 'graphql-tag';
import moment from 'oo-moment';
import _ from 'lodash';
import {
  OrderQuoteItemProduct,
  CartItem,
  CartItemInput,
  CartItemProductInput,
  SHIPPING_SPEED_ENUM,
} from 'inkp-order-sdk/types.g';
import { selectPrintType } from 'inkp-order-sdk/quoter';
import { getDeliverByDate } from 'inkp-order-sdk/shipping';
import { stringifyProductItemId, parseProductItemId } from 'inkp-product-sdk';
import { GREAT_VALUE_PRODUCTS, LIGHTNING_PRODUCTS, TOP_SELLING_PRODUCTS } from 'inkp-product-sdk/product';
import { Design, DesignSide } from 'inkp-design-sdk/types.g';
import { filterValidDesignSides } from 'inkp-design-sdk/design';
import { Product, ProductImage, ProductColor, ProductCategory } from 'inkp-product-sdk/types.g';
import { QuoteSides } from 'oo-print-sdk/types/order';
import mockupRoutes from 'inkp-mockup-sdk/routes';
import { url, routes } from 'inkp-routes/public';
import { Tag, TagBgColors } from 'inkp-components/dist/Components/ProductCard';
import config from 'inkp-config';

import { CartProduct, SizesQuantities, TemporalCartItem } from '../interfaces/CartProduct';
import { GENERAL_PRODUCT_FRAGMENT } from '../graphql/products';
import { RELATED_PRODUCTS_QUERY } from '../components/RelatedProducts';

const DEFAULT_MOCKUP_SIZE: string = 'regular';
const CATEGORY_SCOPE_TYPE: string = 'type';
const UNCATEGORIZED_LABEL = 'uncategorized';

const ICON_LIGHTNING_SRC = `https://${config.s3.bucket}.s3-us-west-2.amazonaws.com/public/icons/icon_lightning.png`;

export const PRODUCT_DETAILS_FRAGMENT = gql`
  fragment ProductDetails on Product {
    ...GeneralProductFragment

    ...RelatedProductsFragment
  }

  ${GENERAL_PRODUCT_FRAGMENT}

  ${RELATED_PRODUCTS_QUERY}
`;

export const PRODUCT_QUERY = gql`
  query Product($id: String!) {
    productById(id: $id) {
      ...ProductDetails
    }
  }

  ${PRODUCT_DETAILS_FRAGMENT}
`;

export const getProductById = (cache: DataProxy, productId: string) => {
  const { productById }: any = cache.readQuery({ query: PRODUCT_QUERY, variables: { id: productId } });
  return productById;
};

export interface ProductsHash {
  [id: string]: Product;
}

export const MAX_PRODUCT_INPUT = 999999;

const getSizeValue = (str?: string) => {
  if (str) {
    const sizeDict = {
      S: 98,
      SM: 99,
      SML: 100,
      MED: 200,
      LRG: 300,
      LG: 301,
      L: 302,
    };
    const numberOfXRegex = /X*/;
    const baseSizeRegex = /[^X].*/;
    const [xString] = numberOfXRegex.exec(str) || [''];
    const [baseSize = ''] = baseSizeRegex.exec(str) || [];
    if (Object.keys(sizeDict).includes(baseSize)) {
      let xMultiplier = 1;
      if (['S', 'SM', 'SML'].includes(baseSize)) xMultiplier = -1;
      const sizeValue = sizeDict[baseSize as (keyof (typeof sizeDict))];
      return sizeValue + xString.length * xMultiplier;
    }
  }
  console.info('Invalid Size Provided', str);
  return 0;
};

export const SIZES_BY_ORDER = [
  'xxsml',
  'xsml',
  'sml',
  'med',
  'lrg',
  'xlg',
  'xxl',
  'xxxl',
  'xxxxl',
  'xxxxxl',
  'xxxxxxl',
];

export const sortSizes = (sizes: string[]) => {
  return _.sortBy(sizes, (size) => {
    return SIZES_BY_ORDER.indexOf(size);
  });
};

export const GetGarmentSizeRange = (sizes: (string | undefined)[]) => {
  const normalizedSizes = sizes.map((size) => {
    return size && size.toUpperCase();
  });
  const allowedSizes = normalizedSizes.filter((size) => getSizeValue(size) > 0);
  allowedSizes.sort((s1, s2) => {
    const s1Val = getSizeValue(s1);
    const s2Val = getSizeValue(s2);
    return s1Val < s2Val ? -1 : 1;
  });
  const [minSize] = allowedSizes;
  const [maxSize] = allowedSizes.reverse();
  return { minSize, maxSize };
};

export const SIDES_BY_ORDER = ['Front', 'Back', 'Left', 'Right'];

export const GetDeliverByDate = (orderPlaceDateString: string, sla: SHIPPING_SPEED_ENUM) => {
  const orderDate = new Date(orderPlaceDateString);
  const deliverByDate = getDeliverByDate(orderDate, sla);
  const result = moment(deliverByDate).format();
  return result;
};

export const ProductToProductItem = ({ colors, ...product }: CartProduct) => {
  const productColors = _.flatten(colors.map((color) => ({ ...product, color })));
  return _.flatten(
    productColors.map(({ color: { sizesObjects, ...color }, ...rest }) => {
      return sizesObjects.map((sizeObject) => ({ color: { sizeObject, ...color }, ...rest }));
    })
  );
};

export const ProductItemToSpQuoteItem = ({
  name: productName,
  color: {
    name: color,
    sizeObjects: { name: size, quantity },
  },
  mockupUrl,
}: {
  name: string;
  color: { name: string; sizeObjects: { name: string; quantity: number } };
  mockupUrl: string;
}) => ({ productItemId: stringifyProductItemId(productName, color, size), quantity, mockupUrl });

export const ProductsToQuoteItems = (productGroup: CartProduct[]) => {
  const firstProduct = _.first(productGroup);
  if (!firstProduct) {
    throw new Error('No Product Included');
  }
  const productItems = _.flatten(productGroup.map((product) => ProductToProductItem(product)));

  const quoteSides = firstProduct.quoteSides;
  const spFormattedProductItems: OrderQuoteItemProduct[] = productItems.map(ProductItemToSpQuoteItem);
  const printType = selectPrintType(quoteSides, productGroup, spFormattedProductItems);
  return spFormattedProductItems.map((item) => {
    return {
      printType,
      product: item,
      quoteSides,
    };
  });
};

export const CartItemsToCartProducts = (cartItems: TemporalCartItem[], products: Product[]): CartProduct[] => {
  const cartItemsGroups = _.groupBy(cartItems, 'designId');
  const cartProductIds: any = [];
  const cartProductsHash: any = {};
  cartItems.forEach((cartItem: CartItem, index: number) => {
    const designId: string = cartItem.designId;
    const mockupUrl: string = cartItem.product.mockupUrl;
    const mockups = cartItem.product.mockups;
    const { productId, color: colorId, size } = parseProductItemId(cartItem.product.productItemId);
    const cartProductId: string = JSON.stringify({
      productId,
      designId,
      colorId,
    });
    const productQuantity: number = cartItem.product.quantity;
    const product: Product | undefined = _.find(products, { id: productId });
    if (!product) {
      throw new Error(`Missing product ${productId} for current cartItem`);
    }

    if (!cartProductsHash.hasOwnProperty(cartProductId)) {
      cartProductIds.push(cartProductId);
      const cartProduct: CartProduct = {
        id: cartProductId,
        designId: designId,
        productId: productId,
        colorId: colorId,
        sizesQuantities: {
          [size]: productQuantity,
        },
        product,
        quoteSides: {},
        mockupUrl,
        mockups,
        cartItemsIndexes: [index],
      };
      cartProductsHash[cartProductId] = cartProduct;
    } else {
      cartProductsHash[cartProductId].sizesQuantities[size] = productQuantity;
      cartProductsHash[cartProductId].cartItemsIndexes.push(index);
    }
  });
  const cartProducts: CartProduct[] = cartProductIds.map((cartProductId: string) => {
    return cartProductsHash[cartProductId];
  });
  return cartProducts;
};

export const CartProductsToCartItems = (cartProducts: CartProduct[]): CartItemInput[] => {
  const res: CartItemInput[] = cartProducts.flatMap((cartProduct: CartProduct): CartItemInput[] => {
    const sizesQuantities: SizesQuantities = cartProduct.sizesQuantities;
    const sizeKeys: string[] = Object.keys(sizesQuantities);
    const productId: string = cartProduct.productId;
    const colorId: string = cartProduct.colorId;
    const designId: string = cartProduct.designId;
    const mockupUrl: string = cartProduct.mockupUrl;
    const mockups = cartProduct.mockups;

    return sizeKeys.map(
      (sizeKey: string): CartItemInput => {
        const productItemId: string = stringifyProductItemId(productId, colorId, sizeKey);
        const quantity: number = sizesQuantities[sizeKey];
        const cartItemProductInput: CartItemProductInput = {
          productItemId,
          quantity,
          mockupUrl,
          mockups,
        };

        return {
          designId,
          product: cartItemProductInput,
        };
      }
    );
  });
  return res;
};

export const GetProductsHashFromCartItems = (cartItems: TemporalCartItem[]): ProductsHash => {
  const productsHash: ProductsHash = cartItems.reduce((productsHash: ProductsHash, currentCartItem: CartItem) => {
    if (currentCartItem.product.product) {
      const product: Product = currentCartItem.product.product;
      if (!productsHash.hasOwnProperty(product.id)) {
        productsHash[product.id] = product;
      }
    }
    return productsHash;
  }, {});
  return productsHash;
};

export const ProductsHashToProductsArray = (productsHash: ProductsHash): Product[] => {
  return Object.keys(productsHash).map((productId: string) => {
    return productsHash[productId];
  });
};

export const ProductsArrayToProductsHash = (products: Product[]): ProductsHash => {
  const productsHash: ProductsHash = {};
  products.forEach((product: Product) => {
    if (!productsHash.hasOwnProperty(product.id)) {
      productsHash[product.id] = product;
    }
  });
  return productsHash;
};

export const sortProductImages = (productImages: ProductImage[]) => {
  const sortedImages = _.sortBy(productImages, (image: ProductImage) => {
    if (image.label && image.label.toLowerCase() === 'front') {
      return 1;
    }
    if (image.label && image.label.toLowerCase() === 'back') {
      return 10;
    }
    return 100;
  });
  return sortedImages;
};

export const findColorObject = (product: Product, color: string) => {
  const convertedColor = color
    .replace('-', ' ')
    .split(' ')
    .map((word) => {
      return _.capitalize(word);
    })
    .join(' ');
  return _.find(product.colors, (productColor) => {
    return productColor.name === convertedColor;
  });
};

export const getProductDetailsImages = (product: Product, color: ProductColor): { label: string; url: string }[] => {
  if (product.detailTemplates && product.detailTemplates.length) {
    const productDetailImages: { label: string; url: string }[] = [];
    for (const detailTemplate of product.detailTemplates) {
      productDetailImages.push({
        label: detailTemplate.side,
        url: url('mockup', mockupRoutes.blank, {
          templateIdentifier: detailTemplate.id,
          color: color.hex,
          side: detailTemplate.side,
          size: DEFAULT_MOCKUP_SIZE,
        }),
      });
    }
    return productDetailImages;
  }
  const backupImages = sortProductImages(color.images as ProductImage[]);
  return backupImages;
};

export const getProductDetailsLink = (product: Product, params?: { [key: string]: string }): string => {
  const id: string = product.id;
  const categoryType: ProductCategory | undefined = product.categories.find((category: ProductCategory) => {
    return category.scope === CATEGORY_SCOPE_TYPE;
  });
  const category: string = categoryType ? categoryType.name : UNCATEGORIZED_LABEL;
  return url('app', routes.app.product.detail, { category, id, ...params });
};

export const getProductColorId = (color: ProductColor): string => {
  return color.name.toLowerCase().replace(/\s/gi, '-');
};

export const getProductIcons = (productId: string) => {
  let tag: Tag | undefined;
  if (GREAT_VALUE_PRODUCTS.includes(productId)) {
    tag = {
      text: 'Great Value',
      color: TagBgColors.BLUE,
    };
  }

  if (TOP_SELLING_PRODUCTS.includes(productId)) {
    tag = {
      text: 'Top Selling',
      color: TagBgColors.PRIMARY,
    };
  }

  let topRightIcon: JSX.Element | undefined;
  if (LIGHTNING_PRODUCTS.includes(productId)) {
    topRightIcon = React.createElement('img', { src: ICON_LIGHTNING_SRC });
  }

  return { tag, topRightIcon };
};

export const designInksDisplay = (design: Design) => {
  const validSides: DesignSide[] = filterValidDesignSides(design);
  const anySidesFull = _.some(validSides, (designSide: DesignSide) => {
    return _.get(designSide, 'artwork.fullColor');
  });
  if (anySidesFull) return 'Full Color';

  const sidesWithInks = _.filter(design.sides, (designSide: DesignSide) => {
    return _.get(designSide, 'artwork') && _.size(designSide!.artwork!.colors) > 0;
  });
  const inksBySide = _.map(sidesWithInks, (designSide: DesignSide) => {
    const colorsCount = _.size(_.get(designSide, 'artwork.colors'));
    const sideName = _.capitalize(designSide.name);
    return `Ink colors: ${colorsCount} ${sideName}`;
  });
  return inksBySide.join(', ');
};
