export enum GTMEvents {
  ACCOUNT_SIGNUP = 'accountSignup',
  ADD_TO_CART = 'addToCart',
  INITIATE_CHECKOUT = 'initiateCheckout',
  PURCHASE = 'Purchase',
  SAVE_DESIGN = 'saveDesign',
  START_DESIGN = 'startDesign',
  VIEW_PRODUCT = 'viewProduct',
  SEARCH_CLIPART = 'searchClipart',
}

export interface GTMUser {
  name: string | null;
  userId: string;
  emailAddress: string | null;
  isInternal: boolean;
  accountCreationDate: Date | null;
  freeShipDate: string | null;
  fastShipDate: string | null;
  fastestShipDate: string | null;
}

export interface GTMItem {
  itemProductTypeParent: string;
  itemProductType: string;
  itemProductId: string;
  itemBrand: string;
  itemPrice: string;
  itemMSRP: string;
  itemImage: string;
  itemLink: string;

  itemDesignId?: string;
  itemDesignName?: string;
  itemDesignProductImageFront?: string;
  itemDesignProductImageBack?: string;
  itemDesignProductImageLeft?: string;
  itemDesignProductImageRight?: string;
  itemDesignColorCountFront?: string;
  itemDesignColorCountBack?: string;
  itemDesignColorCountLeft?: string;
  itemDesignColorCountRight?: string;
  itemDesignIsBlank?: boolean;
  itemDesignPrintType?: string;
  itemDesignEmail?: string;
  itemDesignShareableId?: string;
  itemColorName?: string;
  itemColorHex?: string;

  itemProductQty?: string;
  // itemProductSize: string;

  // itemDesignNotes?: string;
  // itemDesignSharedNameArray?: string[];
  // itemSharedEmailAddressArray?: string[];
  // itemDesignNumberRevisions?: string[];
  itemDesignCreatedDate?: string;
  itemDesignLastUpdateDate?: string;
}

export interface GTMAddressBlock {
  billingName: string;
  billingCompanyName: string;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingCountry: string;
  shippingName: string;
  shippingCompanyName: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingCity: string;
  shippingZip: string;
  shippingState: string;
  shippingCountry: string;
}

export interface GTMCartItem {
  cartItemDesignId: string;
  cartItemDesignShareableId: string;
  cartItemProductTypeParent: string;
  cartItemProductType: string;
  cartItemProductId: string;
  cartItemProductBrand: string;
  cartItemProductQty: number;
  cartItemProductSize: string;
  cartItemProductImageFront: string;
  cartItemProductImageBack: string;
  cartItemProductImageLeft: string;
  cartItemProductImageRight: string;
  cartItemColorName: string;
  cartItemColorHex: string;
  cartItemMSRP: string;
  cartItemPrice: string;
}

export interface GTMCart {
  cartSubtotal: string;
  totalQuantity: number;
  totalLineItems: number;
  totalDesigns: number;
  cartItems: GTMCartItem[];
}

export interface GTMOrder extends GTMAddressBlock {
  orderNumber: string;
  orderSubtotal: string;
  orderGrandTotal: string;
  estDeliveryDate: string;
  shippingSpeed: string;
  proofRequested: boolean;
  couponCode: string;
}

export interface GTMClipart {
  searchTerm?: string;
}

export enum GTMTypes {
  USER = 'User',
  ITEM = 'Item',
  ADDRESS = 'AddressBlock',
  CART = 'Cart',
  ORDER = 'Order',
  CLIPART = 'ClipArt',
}

export type GTMData =
  | { User: GTMUser }
  | { Item: GTMItem | null }
  | { AddressBlock: GTMAddressBlock }
  | { Cart: GTMCart }
  | { Order: GTMOrder }
  | { Clipart: GTMClipart };

export interface GTMEvent {
  event: GTMEvents;
  eventData?: any;
}
